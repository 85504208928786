import axios from "axios";
import { useEffect, useState } from "react";
import Config from "../../config/Config";
import { useDeleteModelLocal } from "../../core/UseAPILocal";
import LookupFactory from "../../lookups/LookupFactory";
import DocumentModel from "../../models/DocumentModel";
import { ButtonComponent } from "../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import { UploadModal } from "../../paradigmLib/uploadModal/UploadModal";
import { ConfirmDeleteModal } from "../modals/ConfirmDeleteModal/ConfirmDeleteModal";
import './uploadSectionAnnual.scss';
import qs from "qs";
import { Table } from "semantic-ui-react";
import { useAuth } from "react-oidc-context";

export class UploadModelAnnual extends DocumentModel {
    annualReportingPeriodId: number;
    jurisdictionId: number;

    constructor(endpoint: string, annualReportingPeriodId: number, jurisdictionId: number) {
        super(endpoint)

        this.active = 1;
        this.annualReportingPeriodId = annualReportingPeriodId;
        this.jurisdictionId = jurisdictionId;
        this.attachmentFileType = "application/pdf";
    }
}

export interface IUploadSectionAnnualProps {
    annualReportPeriodId: number
    jurisdictionId: number
    lookups: LookupFactory
    disabled: boolean
    downloadFile: Function
}

export const UploadSectionAnnual = (props: IUploadSectionAnnualProps) => {
    const auth = useAuth();
    const [annDoc, setAnnDoc] = useState<UploadModelAnnual>(null);
    const [fetchingAnnDoc, setFetchingAnnDoc] = useState<boolean>(true);
    const [annDocEndpoint] = useState<string>(Config.api_endpoint + "annualDocument/annualReport");

    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [deleteModel, setDeleteModel] = useState<UploadModelAnnual>(null);

    const onDeleteModel = (objToDelete: UploadModelAnnual) => {
        console.log('ondeletemodal', objToDelete)
        objToDelete.endpoint = annDocEndpoint;
        setDeleteModel(objToDelete);
    };

    useEffect(() => {
        setAnnDoc(null);
        setFetchingAnnDoc(true);
    }, [props.annualReportPeriodId, props.jurisdictionId]);

    useEffect(() => {
        if (!fetchingAnnDoc) { return; }

        axios.get<UploadModelAnnual>(annDocEndpoint,
            {
                headers: {
                    "Authorization": "Bearer " + auth.user?.access_token
                },
                params: { annualReportPeriodId: props.annualReportPeriodId, jurisdictionId: props.jurisdictionId },
                paramsSerializer: (params: any) => { return qs.stringify(params); }
            })
            .then((response) => {
                setFetchingAnnDoc(false);
                setAnnDoc(response.data);
            });
    }, [fetchingAnnDoc]);

    useDeleteModelLocal(
        auth.user?.access_token,
        deleteModel,
        setDeleteModel,
        () => {
            setDeleteModel(null);
            setAnnDoc(null);
            setFetchingAnnDoc(true);
        }
    );

    useEffect(() => {
        if (!deleteModel) {
            setFetchingAnnDoc(true);
        }

    }, [deleteModel]);

    function buildUrl(file: UploadModelAnnual) {
        if (file && file.id) {
            return Config.api_endpoint + "annualDocument/download/" + file.id;
        }
        return "";
    }

    return (
        <div className="upload-wrapper">
            <div
                style={{
                    color: "#2697d3",
                    textShadow: "1px 1px 1px #c2c2c2 !important",
                    fontWeight: "700",
                    fontSize: "1.2em",
                    margin: "0.5rem 0 1rem",
                }}>
                Store Annual Report
            </div>
            <div className="content-wrapper">
                <div className="pdf-div">
                    <div className="pdf-div-text">
                        <div>
                            You may upload this jurisdiction’s final Annual
                            Report for this reporting period below. Only one
                            file may be stored at a time, uploading a new
                            version will replace the existing version. A zip
                            folder may be used if there are multiple files.
                        </div>
                        <div style={{ width: "100%", height: "10px" }} />
                        <div>
                            <span style={{ fontWeight: "bold" }}>Note:</span>{" "}
                            This function is intended to serve as a convenient
                            repository where the jurisdiction can store the
                            submitted Annual Report file for each reporting
                            period. Files uploaded here will not be shared
                            externally nor submitted to the Regional Board.
                            Unless otherwise specified by the Los Angeles Water
                            Board, each Permittee shall submit its Annual Report
                            to the Los Angeles Water Board in pdf file format
                            via an electronic method (e.g., CD, USB drive,
                            attachment/link in email, etc.).
                        </div>
                    </div>
                    <div className="upload-ann">
                        <ButtonComponent
                            label="Upload"
                            onBtnClick={() => setModalVisible(true)}
                            disabled={props.disabled}
                            theme="primaryBtn"
                        />
                        <UploadModal
                            theme={"primary"}
                            fileUploaded={fileUploaded}
                            setFileUploaded={setFileUploaded}
                            singleUpload={false}
                            uploadAndClose={true}
                            headerText="Upload Your File"
                            documentList={new Array<DocumentModel>(annDoc)}
                            setDocumentList={() => setFetchingAnnDoc(true)}
                            open={modalVisible}
                            setOpen={setModalVisible}
                            getAttachmentModel={() =>
                                new UploadModelAnnual(
                                    "annualDocument/annualReport",
                                    props.annualReportPeriodId,
                                    props.jurisdictionId)
                            }
                        />
                    </div>
                </div>
                <div className="uploaded-doc-div">
                    {annDoc && (
                        <Table celled fixed>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width="2">
                                        File
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width="2">
                                        Date
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width="1">
                                        Delete
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <div className="download-link" onClick={() => {
                                            props.downloadFile(
                                                buildUrl(annDoc), `.${annDoc.attachmentFileName.split('.').pop()}`
                                            );
                                        }}>
                                            {annDoc.attachmentFileName}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {new Date(annDoc.attachmentDate).toDateString()}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <ConfirmDeleteModal
                                            icon="trash alternate outline"
                                            displayName={annDoc.attachmentFileName}
                                            confirmDeleteFunc={onDeleteModel}
                                            itemToDelete={annDoc}

                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    )}
                </div>
            </div>
        </div>
    );
}
