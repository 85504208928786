import { SidebarManagerState } from "../components/mainWrapper/MainWrapper";
import { EditProjectPage } from "../components/sidebars/editProjectPage/EditProjectPage";
import { PrettyProjectPage } from "../components/sidebars/prettyProjectPage/PrettyProjectPage";
import { ReportsSidebar } from "../components/sidebars/reportsSidebar/ReportsSidebar";
import Config from "../config/Config";
import LookupFactory from "../lookups/LookupFactory";
import BmpGeneral from "../models/BmpGeneral";
import { SidebarComponent } from "./SidebarComponent";

export interface ISidebarConfig {
    dispatchSidebar: Function,
    sidebarStore: SidebarManagerState,
    screenSize: number,
    screenDisplayType: string,
    globalSearchParams: any,
    setGlobalSearchParams: Function;
    reportPageToShow: string
    setReportPageToShow: Function;
    sidebarProjectInfoObj: BmpGeneral;
    setSidebarProjectInfoObj: (project: BmpGeneral) => void;
    lookups: LookupFactory;
    setRefreshTable: Function;
}

export const SidebarConfig: React.FC<ISidebarConfig> = (props: ISidebarConfig) => {
    return (
        <div className="home-sidebar-wrapper">
            {
                window.location.href === (Config.appUrl + "reports") &&
                <>
                    <SidebarComponent
                        sidebarName="Reports"
                        sidebarStore={props.sidebarStore}
                        screenSize={props.screenSize}
                        screenDisplayType={props.screenDisplayType}>
                        <ReportsSidebar
                            dispatchSidebar={props.dispatchSidebar}
                            globalSearchParams={props.globalSearchParams}
                            setGlobalSearchParams={props.setGlobalSearchParams}
                            reportPageToShow={props.reportPageToShow}
                            setReportPageToShow={props.setReportPageToShow}
                            lookups={props.lookups} />
                    </SidebarComponent>

                    {props.sidebarProjectInfoObj &&
                        <>
                            <SidebarComponent
                                sidebarName="PrettyProjectPage"
                                sidebarStore={props.sidebarStore}
                                screenSize={props.screenSize}
                                screenDisplayType={props.screenDisplayType}>
                                <PrettyProjectPage
                                    dispatchSidebar={props.dispatchSidebar}
                                    sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                                    lookups={props.lookups}
                                    showEditButton={props.sidebarStore.current.content?.["showEditButton"] ?? false}
                                    show24HourCapacity={props.sidebarStore.current.content?.["show24HourCapacity"]} />
                            </SidebarComponent>

                            <SidebarComponent
                                sidebarName="EditProjectPage"
                                sidebarStore={props.sidebarStore}
                                screenSize={props.screenSize}
                                screenDisplayType={props.screenDisplayType}>
                                <EditProjectPage
                                    project={props.sidebarProjectInfoObj}
                                    setProject={props.setSidebarProjectInfoObj}
                                    lookups={props.lookups}
                                    setRefreshTable={props.setRefreshTable} />
                            </SidebarComponent>
                        </>
                    }
                </>
            }

        </div>
    )
}
