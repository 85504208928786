import { useCallback } from "react";
import { Translation } from "react-i18next";
import LookupFactory from "../../../lookups/LookupFactory";
import BmpGeneral from "../../../models/BmpGeneral";
import BmpGeneralLocation from "../../../models/BmpGeneralLocation";
import { InputComponent } from "../../../paradigmLib/domElements/inputComponent/InputComponent";
import { TextAreaComponent } from "../../../paradigmLib/domElements/textAreaComponent/TextAreaComponent";
import DropdownLutH2O from "../../../paradigmLib/dropdownLut/DropdownLut";
import './ProjectEditPages.scss';

export interface IProjectFundamentalsProps {
    project: BmpGeneral;
    setProject: (project: BmpGeneral) => void;
    lookups: LookupFactory;
    gisQueryResult?: boolean;
    gisQueryError?: string;
    activeItem?: string;
    tempLocation?: BmpGeneralLocation;
    setTempLocation?: (tempLocation: BmpGeneralLocation) => void;
}

export const ProjFundamentals: React.FC<IProjectFundamentalsProps> = (props: IProjectFundamentalsProps) => {
    const onDevelopmentTypeDropdownSelect = useCallback((_: any, data: any) => {
        props.setProject({
            ...props.project,
            bmpGeneralNewAndRedevelopment: {
                ...props.project.bmpGeneralNewAndRedevelopment,
                lidDevelopmentTypeId: data.value,
            },
        });
    }, [props]);

    const onTypeDropdownSelect = useCallback((_: any, data: any) => {
        props.setProject({
            ...props.project,
            bmpTypeId: data.value,
        })
    }, [props]);

    return (
        <div className="edit-proj-fundamentals">
            <Translation>
                {
                    _ =>
                        <>
                            {props.project.bmpClassId && props.project.bmpTypeId &&
                                <>
                                    <InputComponent
                                        label="Name"
                                        placeHolder="Name"
                                        value={props.project.name}
                                        setValue={(value) => props.setProject({ ...props.project, name: value })}
                                        inputType="text"
                                    />
                                    {(props.lookups.getBmpClassByInternalName("watershedControlMeasure") === props.project.bmpClassId
                                        || props.lookups.getBmpClassByInternalName("nonStructuralControlMeasure") === props.project.bmpClassId
                                    ) &&
                                        <InputComponent
                                            label="Previous Name(s)"
                                            placeHolder="Previous Name"
                                            value={props.project.previousName}
                                            setValue={(value) => props.setProject({ ...props.project, previousName: value })}
                                            inputType="text"
                                        />
                                    }
                                    <div className="input-component">
                                        <div className="field">
                                            <div className="basic-input-label-wrapper">
                                                <div className="basic-input-label">
                                                    BMP Class:
                                                </div>
                                            </div>
                                            <div className="content">{props.lookups.getBmpClassById(props.project.bmpClassId)}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        props.project.bmpClassId === props.lookups.getBmpClassByInternalName("newAndRedevelopment") &&
                                        <DropdownLutH2O
                                            dropdownItems={props.lookups && props.project.bmpGeneralNewAndRedevelopment.lidDevelopmentTypeId
                                                ? props.lookups.lutLidDevelopmentType
                                                : []}
                                            dropdownTitle={"Development Type"}
                                            isMultiselect={false}
                                            nullLabel="Please select development type"
                                            onSelect={onDevelopmentTypeDropdownSelect}
                                            parentId={props.project.bmpGeneralNewAndRedevelopment.lidDevelopmentTypeId
                                                ? props.project.bmpGeneralNewAndRedevelopment.lidDevelopmentTypeId
                                                : -1}
                                        />
                                    }

                                    <DropdownLutH2O
                                        dropdownItems={props.lookups && props.project.bmpTypeId ? props.lookups.lutBmpType.filter(x => x.bmpClassId === props.project.bmpClassId) : []}
                                        dropdownTitle={"BMP Type"}
                                        isMultiselect={false}
                                        nullLabel="Please select bmp type"
                                        onSelect={onTypeDropdownSelect}
                                        parentId={props.project.bmpTypeId ? props.project.bmpTypeId : -1}
                                    />

                                    {//only show for structural and also only show for other type and Regional Treatment Facility 
                                        props.project.bmpClassId === props.lookups.getBmpClassByInternalName("watershedControlMeasure") &&
                                        (props.lookups.getBmpTypeByInternalName("regionalTreatmentFacility")
                                            || props.lookups.getBmpTypeByInternalName("otherWCM")
                                        ) &&
                                        //&& otherAndTreatmentDescrTypes.includes(props.project.bmpTypeId)
                                        <InputComponent
                                            label="Description of Treatment Process and Design OR Other Type Specification"
                                            placeHolder="Description of Treatment Process and Design"
                                            value={props.project.bmpTypeOtherAndDescruptionOfTreatment}
                                            setValue={(value) => props.setProject({ ...props.project, bmpTypeOtherAndDescruptionOfTreatment: value })}
                                            inputType="text"
                                        />
                                    }

                                    <TextAreaComponent
                                        label="Project Description"
                                        placeHolder="Description"
                                        value={props.project.description}
                                        setValue={(value) => props.setProject({ ...props.project, description: value })}
                                        rows={5}
                                    />

                                    {props.project.bmpGeneralLocation && props.tempLocation && (
                                        props.lookups.getBmpClassByInternalName("watershedControlMeasure") === props.project.bmpClassId
                                        || props.lookups.getBmpClassByInternalName("newAndRedevelopment") === props.project.bmpClassId
                                    ) &&
                                        <>
                                            <InputComponent
                                                label="Latitude"
                                                placeHolder="Latitude"
                                                value={props.tempLocation.latitude}
                                                setValue={(value) => props.setTempLocation({ ...props.tempLocation, latitude: value })}
                                                inputType="number"
                                            />

                                            <InputComponent
                                                label="Longitude"
                                                placeHolder="Longitude"
                                                value={props.tempLocation.longitude}
                                                setValue={(value) => props.setTempLocation({ ...props.tempLocation, longitude: value })}
                                                inputType="number"
                                            />

                                            <div className="note"><span>Note: </span> Changing coordinates will re-run the GIS Query, possibly resulting in new Jurisdictions or Watersheds</div>

                                            {props.gisQueryResult !== undefined && !props.gisQueryResult &&
                                                <div>Error on the gis query: {props.gisQueryError}</div>
                                            }
                                        </>
                                    }

                                    {props.lookups.getBmpClassByInternalName("nonStructuralControlMeasure") === props.project.bmpClassId &&

                                        <div className="subpage-item-wrapper mt-1point4">
                                            <div className="subpage-item-title">Watershed Management Group(s):</div>
                                            <div className="subpage-item-content">
                                                {props.project.bmpGeneralNonStructuralWatershedGroups.map(({ watershedManagementGroupId }, index) => {
                                                    return (
                                                        <>
                                                            <div key={index}>
                                                                {props.lookups.getWatershedManagementGroupById(watershedManagementGroupId)}
                                                                {index < (props.project.bmpGeneralNonStructuralWatershedGroups.length - 1)
                                                                    ? ", "
                                                                    : ""}&nbsp;
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                }
            </Translation>
        </div>
    )
}
