import { useCallback } from "react";
import { Translation } from "react-i18next";
import BmpGeneralFinancial from "../../../models/BmpGeneralFinancial";
import { AddonInputComponent } from "../../../paradigmLib/domElements/addonInputComponent/AddonInputComponent";
import './ProjectEditPages.scss';
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";

export const ProjFinancial: React.FC<IProjectEditPageProps> = (props: IProjectEditPageProps) => {
    const setFinancial = useCallback((newFinancial: BmpGeneralFinancial) => {
        props.setProject({
            ...props.project,
            bmpGeneralFinancial: newFinancial
        });
    }, [props]);
    return (

        props.project && props.project.bmpClassId &&
        (props.project.bmpClassId === props.lookups.getBmpClassByInternalName("watershedControlMeasure")
            || props.project.bmpClassId === props.lookups.getBmpClassByInternalName("nonStructuralControlMeasure")) &&
        <div className="edit-proj-financial pt-point5">
            <Translation>
                {t =>
                    <>
                        <AddonInputComponent
                            label="Capital Costs"
                            placeHolder="300000"
                            value={props.project.bmpGeneralFinancial.projectCapitalCost ?? ""}
                            setValue={(value) => {
                                const updated = props.project.bmpGeneralFinancial;
                                updated.projectCapitalCost = value !== "" ? value : null;
                                setFinancial(updated);
                            }}
                            inputType="number"
                            theme="primary"
                            addonSide="left"
                            addonContent="$"
                        />
                        <div className="subpage-item-wrapper">
                            <div className="subpage-item-title">Funding Source(s):</div>
                            <div className="subpage-item-content">
                                {props.project.bmpGeneralFundingSources.map(({ fundingSourceId }, index) => {
                                    return (
                                        <>
                                            <div key={index}>
                                                {props.lookups.getFundingSourceById(fundingSourceId)}
                                                {
                                                    index < (props.project.bmpGeneralFundingSources.length - 1)
                                                        ? ", "
                                                        : ""
                                                }
                                                &nbsp;
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>

                        <AddonInputComponent
                            label="Funding Sought"
                            placeHolder="200000"
                            value={props.project.bmpGeneralFinancial.fundingSought ?? ""}
                            setValue={(value) => {
                                const updated = props.project.bmpGeneralFinancial;
                                updated.fundingSought = value !== "" ? value : null;
                                setFinancial(updated);
                            }}
                            inputType="number"
                            theme="primary"
                            addonSide="left"
                            addonContent="$"
                        />
                        <AddonInputComponent
                            label="Funding Obtained"
                            placeHolder="400000"
                            value={props.project.bmpGeneralFinancial.fundingObtained ?? ""}
                            setValue={(value) => {
                                const updated = props.project.bmpGeneralFinancial;
                                updated.fundingObtained = value !== "" ? value : null;
                                setFinancial(updated);
                            }}
                            inputType="number"
                            theme="primary"
                            addonSide="left"
                            addonContent="$"
                        />
                    </>
                }
            </Translation>
        </div>
    )
}
