import { useContext, useEffect, useState } from 'react';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { enumUploadStatus, uploadOptions } from '../../../customTypes/stringLiterals';
import { ButtonComponent } from '../../../paradigmLib/domElements/buttonComponent/ButtonComponent';
import { useAPIUploadExcelLocal } from '../../../core/UseAPILocal';
import QueueRequest, { enumProcessRequest, QueueData } from '../../../models/QueueRequest';
import DropdownLutH2O from '../../../paradigmLib/dropdownLut/DropdownLut';
import { DropdownOptionLut } from '../../../paradigmLib/dropdownLut/IDropdownLutProps';
import Wave from "../../../images/modalWave.svg"
import Wave2 from "../../../images/modalWave2.svg";
import './ExcelUploadModal.scss'
import { useAuth } from 'react-oidc-context';

export interface IExcelUploadModal {
    fileUploaded: any; // upload status
    setFileUploaded: Function // set upload status
    attachmentModel: any; // attachment obj model
    excelList?: Array<any>; // this is the state name of the list of excels (once a new excel is uploaded we will need to push it into this list)
    theme: string; // primary secondary tertiary
    btnLabel: string;
    // foreignKey: Array<number>;
    // foreignKeyName: Array<string>;
    foreignKeyInfo: Array<{ foreignKeyName: string, foreignKey: number }>;
    reFetchListFunc: Function;
    uploadExcelType: enumProcessRequest;
    selectedJurisdictionId: number;
}

export const ExcelUploadModal: any = (props: IExcelUploadModal) => {
    const [open, setOpen] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [file, setFile] = useState<File>();
    const [chooseFile, setChooseFile] = useState('No file chosen...');
    const [formData, setFormData] = useState<FormData>();
    const [error, setError] = useState<enumUploadStatus>(enumUploadStatus.Ready);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorMessageUploadType, setErrorMessageUploadType] = useState<boolean>(false);
    const [queueRequest, setQueueRequest] = useState<QueueRequest>(new QueueRequest());

    const [excelUploadTypeOptions, setExcelUploadTypeOptions] = useState<Array<DropdownOptionLut>>([
        {
            id: 1,
            displayName: "Watershed Control Measure",
            internalName: "watershedControlMeasure",
            displayWeight: 1,
            active: 1
        },
        {
            id: 2,
            displayName: "New and Redevelopment",
            internalName: "newAndRedevelopment",
            displayWeight: 2,
            active: 1
        }
    ]);

    useEffect(() => {
        if (!open) {
            cancel();
        }

    }, [open]);

    const [updateAttachmentModel, setUpdateAttachmentModel] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const auth = useAuth();

    useAPIUploadExcelLocal(auth.user?.access_token, (success, message) => {
        if (success) {
            // parse succeeded
            setQueueRequest(new QueueRequest());

            let fileUploaded = props.fileUploaded;
            fileUploaded = uploadOptions.Success;

            props.setFileUploaded(fileUploaded);
            props.reFetchListFunc(true);
        } else {
            // parse failed

            let fileUploaded = props.fileUploaded;
            fileUploaded = uploadOptions.Failed;

            props.setFileUploaded(fileUploaded);
            setErrorMessage(message);
            console.log('There was an issues with the excel upload');
        }
        setLoading(false);

    }, props.attachmentModel, formData, [updateAttachmentModel]);

    useEffect(() => {
        if (props.fileUploaded === uploadOptions.Success) {
            setTimeout(
                function () {
                    cancel();
                }
                    .bind(this),
                4000
            );
        }

    }, [props.fileUploaded]);

    const clearErrors = (callback?: any) => {
        setError(enumUploadStatus.Ready);
        setErrorMessage("");
        setErrorMessageUploadType(false);
        let fileUploaded = props.fileUploaded;
        fileUploaded = uploadOptions.Waiting;

        props.setFileUploaded(fileUploaded);

        if (callback) {
            callback();

        };
    }

    const cancel = () => {
        setOpen(false);
        let fileUploaded = props.fileUploaded;
        fileUploaded = uploadOptions.Waiting;
        props.setFileUploaded(fileUploaded);
        setError(enumUploadStatus.Ready);
        setChooseFile('No file chosen...');
    }

    const handleFileSelect = (evt) => {
        let newFormData: FormData = new FormData();
        let file: File = evt.target.files[0];
        if (file) {
            if (file.size > 1024 * 1024 * 50) {
                setError(enumUploadStatus.FileTooLarge);

            } else {
                newFormData.append("data", file);

                setError(enumUploadStatus.Ready);
                setFile(file);
                setFormData(newFormData);
                setFileSelected(true);
                setChooseFile(file.name);
            }
        }

    };

    const uploadSelectedFile = () => {
        if (fileSelected && queueRequest.processRequested) {
            let newFormData: FormData = formData;
            let stateCopy: any = { ...queueRequest };
            stateCopy.queueData = new QueueData();
            stateCopy.queueData.jurisdictionId = props.selectedJurisdictionId;
            setQueueRequest(stateCopy);

            newFormData.append("requestString", JSON.stringify(stateCopy));
            setFormData(newFormData);

            //this calls clearErrors and passes in the this.props.uploadFunc as the callback function
            clearErrors(() => {
                setLoading(true);
                setUpdateAttachmentModel(!updateAttachmentModel);

            });
        } else {
            setError(enumUploadStatus.SelectFile);
            setErrorMessageUploadType(true);
        };

    };

    const onSelectExcelUploadType = (e: any, d: any) => {
        let stateCopy: any = { ...queueRequest };
        stateCopy.processRequested = d.value;
        setQueueRequest(stateCopy);
        if (d.value) {
            setError(enumUploadStatus.Ready);
            setErrorMessageUploadType(false);
        }
    }

    return (
        <Modal className="excel-upload-modal-component"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<div className="upload-btn"><Icon className="upload-icon" name="cloud upload" /> {props.btnLabel}</div>}
        >
            <Modal.Header>
                <div className="excel-upload-modal-title">Upload Excel File Here</div>
                <div className="wave-div">
                    <img src={Wave} alt="wave" className="wave" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <div className="inner-wrapper">
                        <div className="uploader mb-1">

                            {
                                error === enumUploadStatus.SelectFile &&
                                <span className="text-danger ml-2 mb-1">***Please select a file to upload</span>
                            }
                            {
                                error === enumUploadStatus.FileTooLarge &&
                                <span className="text-danger ml-2 mb-1">***File is too large. Please use files under 20 MB.</span>
                            }
                            {
                                props.fileUploaded === uploadOptions.PDFExpected &&
                                <span className="text-danger ml-2 mb-1">***Please a attach PDF file only</span>
                            }
                            {
                                errorMessageUploadType &&
                                <span className="text-danger ml-2 mb-1">***Please select an excel upload type</span>
                            }

                            {
                                props.fileUploaded === uploadOptions.ShapeExpected &&
                                <span className="text-danger ml-2 mb-1">***Please a attach Shape file only</span>
                            }

                            <div className="file-upload">
                                <div className="file-select">
                                    <div className="file-select-button" id="fileName">Choose File</div>
                                    <div className="file-select-name" id="chooseFile">{chooseFile}</div>
                                    <input type="file" accept=".xlsx" name="chooseFile" id="chooseFile" onChange={handleFileSelect} />
                                </div>
                            </div>
                            <div className='mt-1 excel-dropdown-div'>
                                <DropdownLutH2O
                                    dropdownItems={excelUploadTypeOptions}
                                    dropdownTitle={"Please select excel upload type"}
                                    isMultiselect={false}
                                    nullLabel="Select upload type"
                                    onSelect={onSelectExcelUploadType}
                                    parentId={queueRequest.processRequested}
                                />
                            </div>

                        </div>
                    </div>

                </Modal.Description>
            </Modal.Content>

            <div className="action-div">
                <div className="wave-div">
                    <img src={Wave2} alt="wave" className="wave" />
                </div>
                {
                    props.fileUploaded === uploadOptions.Success &&
                    <Modal.Actions className="pdf-modal-success-actions">
                        <div className="pr-1">
                            Upload Completed - Please check 'Recent Bulk Uploads' Table for Status and Errors.
                        </div>
                        <div>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>
                        <Button color="grey" onClick={cancel}>Close</Button>
                    </Modal.Actions>
                }
                {
                    props.fileUploaded === uploadOptions.Failed &&
                    <div className="error-container">
                        <div className="error-div text-danger">
                            ***There was an issue with your upload***
                            <div className="additional-info-error-text">
                                <br /><b>Additional Info: </b>
                                {errorMessage}
                            </div>
                        </div>
                        <Modal.Actions className="pdf-modal-actions">
                            <ButtonComponent
                                onBtnClick={uploadSelectedFile}
                                label="Try Again"
                                theme={"primaryBtn"} // primaryBtn secondaryBtn tertiaryBtn
                            />
                            <Button color="grey" onClick={cancel}>Cancel</Button>
                        </Modal.Actions>
                    </div>
                }
                {
                    ((props.fileUploaded === uploadOptions.Waiting || props.fileUploaded === uploadOptions.PDFExpected || props.fileUploaded === uploadOptions.ShapeExpected)) &&
                    <Modal.Actions className="pdf-modal-actions">
                        <ButtonComponent
                            onBtnClick={uploadSelectedFile}
                            label="Upload"
                            disabled={error !== enumUploadStatus.Ready || !fileSelected || queueRequest.processRequested === undefined || queueRequest.processRequested === null || !(chooseFile.toLowerCase().endsWith(".xlsx"))}
                            loading={loading}
                            theme={"primaryBtn"} // primaryBtn secondaryBtn tertiaryBtn
                        />
                        <Button color="grey" onClick={cancel}>Cancel</Button>
                    </Modal.Actions>
                }
            </div>
        </Modal>
    )
}
