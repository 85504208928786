import React, { useState } from "react";
import { useEffect } from "react";
import { Table } from "semantic-ui-react";
import { ISortableTableComponentProps } from "./ISortableTablePropsComponent";
// import { ISortableTableProps } from "./ISortableTablePropsComponent";

// need a few properties:
// data array
// optional field display name prop; otherwise use data prop name directly
// list of properties displayed (in order of display)
// sort (bool or array of fields to sortable)
// click even listener (bool or array of fields)



export const SortableTableComponent: React.FC<ISortableTableComponentProps> = ({ tableData, columnArr, headerNames, headerBackgroundColor, headerTextColor, sortable, celled, fixed, striped, columnSortType }) => {

    const [sortTable, dispatch] = React.useReducer(exampleReducer, {
        column: null,
        data: tableData,
        direction: null,
    })
    const { column, data, direction } = sortTable

    const [headerArr, setHeaderArr] = useState<string[]>(new Array<string>())
    const [dataArr, setDataArr] = useState<string[]>(new Array<string>())

    useEffect(() => {
        let columnNames = [];
        if (columnArr) {
            columnNames = columnArr;
        } else {
            if (tableData) {
                for (var prop in tableData[0]) {
                    if (Object.prototype.hasOwnProperty.call(tableData[0], prop)) {
                        columnNames.push(prop);
                    }
                }
            }
        }

        console.log('set dataarr eff');
        setDataArr(columnNames);

        let hdrNames = [];
        if (headerNames) {
            hdrNames = headerNames;
        } else {
            if (columnNames) {
                hdrNames = columnNames;
            }
        }

        setHeaderArr(hdrNames);

        dispatch({ type: 'RESET', column: '' })

    }, [tableData, columnArr, headerNames])

    function exampleReducer(state, action) {
        switch (action.type) {
            case 'CHANGE_SORT':
                if (state.column === action.column) {
                    return {
                        ...state,
                        data: state.data.slice().reverse(),
                        direction:
                            state.direction === 'ascending' ? 'descending' : 'ascending',
                    }
                }

                let newData = [];
                console.log('change sort', state, action.column);
                if (columnSortType && columnArr) {
                    // get array index
                    let idx = -1;
                    columnArr.forEach((d, i) => {
                        if (d === action.column) { idx = i; }
                    })
                    console.log('idx', idx, tableData)
                    // then look into columnSortType for sorting type text vs number
                    let sortType = columnSortType[idx];
                    if (sortType === 'text') {
                        newData = tableData.sort(function (a: any, b: any) {
                            if (a[action.column].toLowerCase() < b[action.column].toLowerCase()) { return -1; }
                            if (a[action.column].toLowerCase() > b[action.column].toLowerCase()) { return 1; }
                            return 0;
                        });
                    } else {
                        newData = tableData.sort(function (a: any, b: any) { return a[action.column] - b[action.column]; });
                    }
                } else {
                    newData = tableData.sort(function (a: any, b: any) { return a[action.column] - b[action.column]; });
                }

                return {
                    column: action.column,
                    data: newData,
                    direction: 'ascending',
                }
            case 'RESET':
                return {
                    column: null,
                    data: tableData,
                    direction: null,
                }
            default:
                throw new Error()
        }
    }

    return (
        <div className='sortable-table-component'>
            <Table
                sortable={sortable === true}
                celled={celled === true}
                fixed={fixed === true}
                striped={striped === true}
                style={{ width: "100%", boxShadow: "1px 1px 2px 0px #afafaf" }}
            >
                <Table.Header>
                    <Table.Row >
                        {dataArr.length > 0 && dataArr.map((dataName, i) => (
                            <Table.HeaderCell
                                key={dataName + i}
                                sorted={column === dataName ? direction : null}
                                onClick={() => {
                                    if (sortable === true) { dispatch({ type: 'CHANGE_SORT', column: dataName }) }
                                }}
                                style={{ backgroundColor: headerBackgroundColor, color: headerTextColor }}
                            >
                                {headerArr[i]}
                            </Table.HeaderCell>
                        ))}
                        {dataArr.length === 0 && <Table.HeaderCell>No data</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.length > 0 && data.map((data, index) => (
                        <Table.Row key={index}>
                            {dataArr.length > 0 && dataArr.map((dataName, idx) => (
                                <Table.Cell key={idx}>{data[dataName]}</Table.Cell>
                            ))}
                            {dataArr.length === 0 && <Table.Cell>No data</Table.Cell>}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}