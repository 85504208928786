import { useCallback } from "react";
import { Translation } from "react-i18next";
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";
import BmpGeneralAttributes from "../../../models/BmpGeneralAttributes";
import { AddonInputComponent } from "../../../paradigmLib/domElements/addonInputComponent/AddonInputComponent";
import "./ProjectEditPages.scss";

export const ProjAttributes: any = (props: IProjectEditPageProps) => {
    const setAttributes = useCallback(
        (newAttributes: BmpGeneralAttributes) => {
            props.setProject({
                ...props.project,
                bmpGeneralAttributes: newAttributes,
            });
        },
        [props]
    );

    return (
        <div className="edit-proj-attributes pt-point5">
            <Translation>
                {(t) => (
                    <>
                        {props.project && props.project.bmpClassId && (
                            <>
                                {(props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) ||
                                    props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "newAndRedevelopment"
                                    )) && (
                                        <AddonInputComponent
                                            label="Project Footprint"
                                            placeHolder="5.7"
                                            value={props.project.bmpGeneralAttributes.footprintAc ?? ""}
                                            setValue={(value) => {
                                                const updated = props.project.bmpGeneralAttributes;
                                                updated.footprintAc = value !== "" ? value : null;
                                                setAttributes(updated);
                                            }}
                                            inputType="number"
                                            theme="primary"
                                            addonSide="right"
                                            addonContent="acres"
                                        />
                                    )}

                                {props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) && (
                                        <AddonInputComponent
                                            label="Native Soil Infiltration Rate"
                                            placeHolder="3.2"
                                            value={props.project.bmpGeneralAttributes.soilInfiltrationRateInHr ?? ""}
                                            setValue={(value) => {
                                                const updated = props.project.bmpGeneralAttributes;
                                                updated.soilInfiltrationRateInHr = value !== "" ? value : null;
                                                setAttributes(updated);
                                            }}
                                            inputType="number"
                                            theme="primary"
                                            addonSide="right"
                                            addonContent="in/hr"
                                        />
                                    )}

                                {(props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) ||
                                    props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "newAndRedevelopment"
                                    )) && (
                                        <>
                                            <AddonInputComponent
                                                label="Drainage Area"
                                                placeHolder="5"
                                                value={props.project.bmpGeneralAttributes.drainageAreaAc ?? ""}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralAttributes;
                                                    updated.drainageAreaAc = value !== "" ? value : null;
                                                    setAttributes(updated);
                                                }}
                                                inputType="number"
                                                theme="primary"
                                                addonSide="right"
                                                addonContent="acres"
                                            />

                                            <AddonInputComponent
                                                label="% Imperviousness of Drainage Area"
                                                placeHolder="12"
                                                value={props.project.bmpGeneralAttributes.drainagePercentImperviousPercent ?? ""}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralAttributes;
                                                    updated.drainagePercentImperviousPercent = value !== "" ? value : null;
                                                    setAttributes(updated);
                                                }}
                                                inputType="number"
                                                theme="primary"
                                                addonSide="right"
                                                addonContent="%"
                                            />
                                        </>
                                    )}
                                {props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) && (
                                        <>
                                            <AddonInputComponent
                                                label="Projected Project Storage Capacity in WMP"
                                                placeHolder="2.3"
                                                value={props.project.bmpGeneralAttributes.projectedStorageCapacityAcft ?? ""}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralAttributes;
                                                    updated.projectedStorageCapacityAcft = value !== "" ? value : null;
                                                    setAttributes(updated);
                                                }}
                                                inputType="number"
                                                theme="primary"
                                                addonSide="right"
                                                addonContent="ac-ft"
                                            />
                                        </>
                                    )}
                                {(props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) ||
                                    props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "newAndRedevelopment"
                                    )) && (
                                        <>
                                            <AddonInputComponent
                                                label="Actual Project Storage Capacity"
                                                placeHolder="3.6"
                                                value={props.project.bmpGeneralAttributes.actualStorageCapacityAcft ?? ""}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralAttributes;
                                                    updated.actualStorageCapacityAcft = value !== "" ? value : null;
                                                    setAttributes(updated);
                                                }}
                                                inputType="number"
                                                theme="primary"
                                                addonSide="right"
                                                addonContent="ac-ft"
                                            />
                                        </>
                                    )}

                                {props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) && (
                                        <AddonInputComponent
                                            label="24-hour Project Storage Capacity"
                                            placeHolder="3.6"
                                            value={props.project.bmpGeneralAttributes.dailyStorageCapacityAcft ?? ""}
                                            setValue={(value) => {
                                                const updated = props.project.bmpGeneralAttributes;
                                                updated.dailyStorageCapacityAcft = value !== "" ? value : null;
                                                setAttributes(updated);
                                            }}
                                            inputType="number"
                                            theme="primary"
                                            addonSide="right"
                                            addonContent="ac-ft"
                                        />
                                    )}

                                {props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) &&
                                    props.project.bmpTypeId && (
                                        <AddonInputComponent
                                            label="Infiltration Well Drawdown Rate"
                                            placeHolder="3.6"
                                            value={props.project.bmpGeneralAttributes.greenStreetInfiltrationWellDrawdownRateCfs ?? ""}
                                            setValue={(value) => {
                                                const updated = props.project.bmpGeneralAttributes;
                                                updated.greenStreetInfiltrationWellDrawdownRateCfs = value !== "" ? value : null;
                                                setAttributes(updated);
                                            }}
                                            inputType="number"
                                            theme="primary"
                                            addonSide="right"
                                            addonContent="cfs"
                                        />
                                    )}

                                {props.project.bmpClassId ===
                                    props.lookups.getBmpClassByInternalName(
                                        "watershedControlMeasure"
                                    ) && (
                                        <>
                                            <AddonInputComponent
                                                label="If present, what is diversion structure design rate? If inline, leave blank"
                                                placeHolder="3.6"
                                                value={props.project.bmpGeneralAttributes.regionalDiversionDesignRateCfs ?? ""}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralAttributes;
                                                    updated.regionalDiversionDesignRateCfs = value !== "" ? value : null;
                                                    setAttributes(updated);
                                                }}
                                                inputType="number"
                                                theme="primary"
                                                addonSide="right"
                                                addonContent="cfs"
                                            />
                                            <AddonInputComponent
                                                label="Drawdown Rate for Use or Treatment and Release"
                                                placeHolder="3.6"
                                                value={props.project.bmpGeneralAttributes.regionalDrawdownRateCfs ?? ""}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralAttributes;
                                                    updated.regionalDrawdownRateCfs = value !== "" ? value : null;
                                                    setAttributes(updated);
                                                }}
                                                inputType="number"
                                                theme="primary"
                                                addonSide="right"
                                                addonContent="cfs"
                                            />
                                            <AddonInputComponent
                                                label="Green Street Length"
                                                placeHolder="3.6"
                                                value={props.project.bmpGeneralAttributes.greenStreetLengthFt ?? ""}
                                                setValue={(value) => {
                                                    const updated = props.project.bmpGeneralAttributes;
                                                    updated.greenStreetLengthFt = value !== "" ? value : null;
                                                    setAttributes(updated);
                                                }}
                                                inputType="number"
                                                theme="primary"
                                                addonSide="right"
                                                addonContent="ac-ft"
                                            />
                                        </>
                                    )}
                            </>
                        )}
                    </>
                )}
            </Translation>
        </div>
    );
};
