import { useNavigate } from "react-router";
import { Dropdown } from "semantic-ui-react";
import Config from "../../config/Config";
import { NewWrampsLogo } from "./NewWrampsLogo";
import { Translation, useTranslation } from "react-i18next";
import { AuthLoginComponent } from "../authComponents/authLoginComponent/authLoginComponent";
import "./Navbar.scss";
import { useAuth } from "react-oidc-context";

export const Navbar: any = (props: any) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const auth = useAuth();

    // const getUserInfo = (urs: any) => {
    //     if(urs && urs.oidcUser){
    //         //let roles = urs.oidcUser.profile["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
    //         //console.log('user profile', urs.oidcUser.profile, roles, urs.oidcUser)
    //         console.log('auth', urs.oidcUser);
    //     }
    //     //return urs.oidcUser.profile.name;
    //     return true;
    // }

    return (
        <div className="navbar">
            <Translation>
                {t =>
                    <div className={`overlay`}
                        style={{
                            background:
                                Config.env === "qa" ? "rgb(255, 204, 0)" : "#ffffff",
                        }}>
                        <div className="nav-inner-container">
                            <div className="left-container">
                                <div
                                    className="bars-div"
                                    title={t("welcomeTitle")}
                                    onClick={() => navigate(Config.routeUrl + "welcome")}
                                >
                                    <NewWrampsLogo />
                                </div>
                            </div>

                            {/* starts English/Spanish links in Navbar */}
                            {props.screenDisplayType === "desktop" && (
                                <>

                                    <div className="nav-show">

                                        {/* DONT DELETE - WE WILL BE PUTTING THESE BACK WHEN TRANSLATIONS ARE AVAILABLE */}

                                        {/* English/Spanish options */}
                                        {/* <div className="nav-lang-div">
                                                <div
                                                className={`nav-menu-show expand`}
                                                onClick={() => placeholderFunc()}
                                                >{t("navbarEng")}
                                                </div>
                                                <div
                                                className={`nav-menu-show expand`}
                                                onClick={() => placeholderFunc()}
                                                title="Coming Soon"
                                                >
                                                {t("navbarEsp")}
                                                </div>
                                            </div> */}
                                        {/* End of English/Spanish */}

                                        <div className="nav-items-div">

                                            <div
                                                className={`nav-menu-show expand
                                                            ${window.location.href === Config.appUrl + "welcome"
                                                        ? "active"
                                                        : ""}`}
                                                onClick={() =>
                                                    navigate(Config.routeUrl + "welcome")
                                                }
                                            >{t("navbarWelcome")}
                                            </div>

                                            {
                                                auth.user &&
                                                <>
                                                    <div
                                                        className={`nav-menu-show expand
                                                                    ${window.location.href === Config.appUrl + "reports"
                                                                ? "active"
                                                                : ""}`}
                                                        onClick={() =>
                                                            navigate(Config.routeUrl + "reports")}>{t("navbarReporting")}
                                                    </div>

                                                    <div
                                                        className={`nav-menu-show expand
                                                                    ${window.location.href === Config.appUrl + "help"
                                                                ? "active"
                                                                : ""}`}
                                                        onClick={() =>
                                                            navigate(Config.routeUrl + "help")}>{t("navbarHelp")}
                                                    </div>
                                                </>
                                            }

                                            {/* <div
                                                            className={`nav-menu-show expand
                                                            ${window.location.href === Config.appUrl + "about"
                                                                    ? "active"
                                                                    : ""}`}
                                                            onClick={() =>
                                                                navigate(Config.routeUrl + "about")}>{t("navbarAbout")}
                                                        </div> */}

                                            {!auth.user &&
                                                <AuthLoginComponent
                                                    loginLabel={t("navbarLogin")}
                                                    logoutLabel={t("navbarLogout")}
                                                />
                                            }

                                            {/* Start of hamburger menu that always shows */}
                                            {auth.user &&
                                                <div className="nav-dropdown">
                                                    <Dropdown
                                                        icon="bars"
                                                        className="menu-dropdown"
                                                        fluid
                                                        pointing="top right"
                                                    >
                                                        <Dropdown.Menu id="styledMenu">
                                                            {auth.user && (auth.user.profile.role as string).indexOf("admin") > -1 &&
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        window.location.href = Config.authInfo.server + "/UserPermissions?returnUrl=" + Config.appUrl}
                                                                    text={t("navbarAdmin")}
                                                                />
                                                            }

                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    window.location.href = Config.authInfo.server + "/Manage/ChangePassword"}
                                                                text={t("navbarChngPw")}
                                                            />
                                                            {/* name this div "item" so it would match the styling as <Dropdown.Item> */}
                                                            <div className="item">
                                                                <AuthLoginComponent
                                                                    loginLabel={t("navbarLogin")}
                                                                    logoutLabel={t("navbarLogout")}
                                                                />
                                                            </div>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            } {/* End of hamburger menu that always shows*/}
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Start of mobile view dropdown */}
                            {
                                props.screenDisplayType === "mobile" &&
                                <>
                                    <div className="nav-dropdown">
                                        <Dropdown
                                            icon="bars"
                                            className="menu-dropdown nav-menu-item"
                                            fluid
                                            pointing="top right"
                                        >
                                            <Dropdown.Menu id="mobileMenu">
                                                {/* DONT DELETE - WE WILL BE ADDING THESE BACK WHEN TRANSLATION BECOME AVAILABLE */}
                                                {/* <Dropdown.Item
                                                        onClick={() => placeholderFunc()}
                                                        text={t("navbarEng")}
                                                        />
                                                        <Dropdown.Item
                                                        onClick={() => placeholderFunc()}
                                                        text={t("navbarEsp")}
                                                        /> */}

                                                {/* <Dropdown.Divider /> */}

                                                <Dropdown.Item
                                                    onClick={() =>
                                                        navigate(Config.routeUrl + "welcome")}
                                                    text={t("navbarWelcome")}
                                                />

                                                {auth.user &&
                                                    <>
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                navigate(Config.routeUrl + "reports")}
                                                            text={t("navbarReporting")}
                                                        />
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                window.location.href = Config.authInfo.server + "/Manage/ChangePassword"}
                                                            text={t("navbarChngPw")}
                                                        />
                                                    </>
                                                }

                                                {/* <Dropdown.Item
                                                                onClick={() =>
                                                                    navigate(Config.routeUrl + "about")}
                                                                text={t("navbarAbout")}
                                                            /> */}

                                                {auth.user && (auth.user.profile.role as string[]).indexOf("admin") > -1 &&
                                                    <Dropdown.Item
                                                        onClick={() =>
                                                            window.location.href = Config.authInfo.server + "/UserPermissions?returnUrl=" + Config.appUrl}
                                                        text={t("navbarAdmin")}
                                                    />
                                                }

                                                <Dropdown.Item>
                                                    <AuthLoginComponent
                                                        loginLabel={t("navbarLogin")}
                                                        logoutLabel={t("navbarLogout")}
                                                    />
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                            }
                            {/* End of mobile view dropdown */}
                        </div>
                    </div>
                }
            </Translation>
        </div>
    );
};
