import "./MapPopup.scss";

export interface IMapPopupProps {
    feature: any;
}

const MapPopup = (props: IMapPopupProps) => {

    return (
        <>
            {props.feature.attributes.name &&
                <div className="popup-container-wrapper">
                    <div className="popup-container">
                        {props.feature.attributes.totalCapacity > 0 &&
                            <div className="popup-icon" >
                                <div className="popup-value">{props.feature.attributes.totalCapacity?.toFixed(2)}</div>
                                <div className="popup-unit">ac-ft</div>
                            </div>
                        }
                        <div className="popup-text">
                            {props?.feature?.attributes?.name}
                        </div>
                    </div>
                    <div className="bottom-arrow"></div>
                </div>
            }
        </>
    );
};

export default MapPopup;