import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import { Button, Dimmer, Loader, Menu, Segment, Table } from "semantic-ui-react";
import { Translation } from "react-i18next";
import { GlobalAuthInfoContext, IGlobalAuthInfo } from "../../mainWrapper/MainWrapper";
import { ExcelUploadModal } from "../../modals/excelUploadModal/ExcelUploadModal";
import { uploadOptions } from "../../../customTypes/stringLiterals";
import BmpGeneral from "../../../models/BmpGeneral";
import LookupFactory from "../../../lookups/LookupFactory";
import { useAPIFetchAllLocal } from "../../../core/UseAPILocal";
import QueueRequest from "../../../models/QueueRequest";
import Config from "../../../config/Config";
import axios from "axios";
import qs from "qs";
import { FilterComponent, ManageProjectFilter } from "../filterComponent/FilterComponent";
import { QueueModal } from "../../modals/QueueModal/QueueModal";
import QueueItemModel from "../../../models/viewModels/QueueItemModel";
import { ButtonComponent } from "../../../paradigmLib/domElements/buttonComponent/ButtonComponent";
import DropdownLutH2O from "../../../paradigmLib/dropdownLut/DropdownLut";
import { TooltipModal } from "../../modals/tooltipModal/TooltipModal";
import Download, { GetById } from "../../../core/DownloadUtil";
import { toast, ToastContainer } from "react-toastify";
import { ConfirmArchiveModal } from "../../modals/ConfirmArchiveModal/ConfirmArchiveModal";
import { ProjectList } from "./ProjectList";
import { ConfirmDeleteModal } from "../../modals/ConfirmDeleteModal/ConfirmDeleteModal";
import MpdMapConfig from "./MpdMapConfig";
import Map, { GeoJSON, Layer, WMS } from "@paradigmh2o/react-map";
import inProgressWCM from '../../../images/InProgressWCMMarker.png';
import CompleteWCMMarker from '../../../images/CompleteWCMMarker.png';
import newRedev from '../../../images/newRedevMarker.png';
import { Legend } from "../buildWatershedForm/wmgPublicDashboard/Map/Legend";
import Extent from "@arcgis/core/geometry/Extent";
import { GeoJsonService } from "../../../services/GeoJsonService";
import "./ManageProjectData.scss";
import { useAuth } from "react-oidc-context";

export interface IManageProjectDataProps {
    setSidebarProjectInfoObj: Function;
    dispatchSidebar: Function;
    visible: boolean;
    setVisible: Function;
    lookups: LookupFactory;
    refreshTable: boolean;
    setRefreshTable: Function;
}

export class BmpSearchResponse {
    bmps: BmpGeneral[];
    pageCount: number;
    page: number;
}

export const ManageProjectData: React.FC<IManageProjectDataProps> = (props: IManageProjectDataProps) => {
    const globalAuthInfoContext = useContext(GlobalAuthInfoContext);
    const auth = useAuth();

    const [fileUploaded, setFileUploaded] = useState<uploadOptions>(
        uploadOptions.Waiting
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [bmpGeneral, setBmpGeneral] = useState<Array<BmpGeneral>>(new Array<BmpGeneral>());
    const [activeItem, setActiveItem] = useState<string>("Uploaded Project List");

    const [queueItemList, setQueueItemList] = useState<Array<QueueItemModel>>(new Array<QueueItemModel>());
    const [sortedQueueItemList, setSortedQueueItemList] = useState<Array<QueueItemModel>>(new Array<QueueItemModel>());
    const [fetchingQueueItemList, setFetchingQueueItemList] = useState<boolean>(false);

    const [bmpEndpoint] = useState<string>(Config.api_endpoint + "bmp/search");
    const [bmpEndpointRem] = useState<string>(Config.api_endpoint + "bmp/delete");

    const [selectedJurisdictionId, setSelectedJurisdictionId] = useState<number>(-1);

    const [filter, setFilter] = useState<ManageProjectFilter>(new ManageProjectFilter());
    const [finalFilter, setFinalFilter] = useState<ManageProjectFilter>(new ManageProjectFilter());

    const [refreshMap, setRefreshMap] = useState<boolean>(false);

    const [checkedSet, setCheckedSet] = useState<Set<number>>(new Set<number>());

    const [newRedevelopmentAvailable, setNewRedevelopmentAvailable] = useState<boolean>(true);
    const [wcmAvailable, setWcmAvailable] = useState<boolean>(true);

    const geoJsonService = useMemo(() => new GeoJsonService(auth.user?.access_token), [auth]);

    useEffect(() => {
        if (globalAuthInfoContext.jurisdictionList !== undefined) {
            let sortedList: any = globalAuthInfoContext.jurisdictionList.sort((a: any, b: any) => { return (a.displayWeight - b.displayWeight) })
            setSelectedJurisdictionId(sortedList[0].id);
        }

    }, [globalAuthInfoContext]);

    useEffect(() => {
        if (activeItem === "Project Map") {
            setRefreshMap(true);
        }
    }, [activeItem])

    useEffect(() => {
        if (refreshMap === true) {
            setTimeout(() => setRefreshMap(false), 500);
        }
    }, [refreshMap])

    useAPIFetchAllLocal(auth.user?.access_token, new QueueItemModel(), fetchingQueueItemList, setFetchingQueueItemList, setQueueItemList, { jurisdictionId: selectedJurisdictionId, active: 1 }, (data) => {
    });

    const updateSelection = (id: number) => {
        let newSet = new Set(checkedSet);
        if (newSet.has(id)) {// remove
            newSet.delete(id);
        } else {// add
            newSet.add(id);
        }
        setCheckedSet(newSet);
    }

    const updateSelectionAll = () => {
        console.log('bmplist all', bmpGeneral.length)
        if (checkedSet.size === bmpGeneral.length) { // something is in the set, unselect
            let newSet = new Set<number>();
            setCheckedSet(newSet);
        } else { // select all
            let newSet = new Set<number>();
            bmpGeneral.forEach((item) => { newSet.add(item.id); });
            setCheckedSet(newSet);
        }
    }

    const deleteSelected = () => {
        axios.post<BmpGeneral>(bmpEndpointRem,
            Array.from(checkedSet),
            {
                headers: {
                    "Authorization": `Bearer ${auth.user?.access_token}`,
                    method: 'POST'
                }
            })
            .then((response) => {
                console.log('response', response)
                setCheckedSet(new Set<number>());
                getData();
            });
    }

    const deleteBulkUpload = (id: number) => {
        const deleteEndpoint = Config.api_endpoint + "QueueItem/SetQueueItemActiveToZero";

        var form = new FormData();
        form.append("queueItemId", id.toString());

        axios.put<string>(deleteEndpoint, form, {
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        })
            .then((response) => {
                // refresh table
                console.log('deleteConfirmModal deleted file successfully');
                setFetchingQueueItemList(true);
            })
            .catch(() => {
                console.log('deleteConfirmModal could not delete file');
            });
    }

    const controllerRef = useRef<AbortController | null>();

    const getData = useCallback(() => {
        if (finalFilter === undefined || finalFilter.jurisdictions === undefined || finalFilter.jurisdictions.length === 0) { return; }

        if (controllerRef.current) { controllerRef.current.abort(); }
        const controller = new AbortController();
        controllerRef.current = controller;

        axios.get<BmpSearchResponse>(bmpEndpoint,
            {
                headers: {
                    "Authorization": "Bearer " + auth.user?.access_token
                },
                signal: controllerRef.current?.signal,
                params: finalFilter,
                paramsSerializer: (params: any) => { return qs.stringify(params); }
            })
            .then((response) => {
                response.data.bmps.map((bmp: BmpGeneral) => {
                    return bmp;
                })
                setBmpGeneral(response.data.bmps);
                setCheckedSet(new Set<number>());
                controllerRef.current = null;
                setIsLoading(false);
                props.setRefreshTable(false);
            })
            .catch(function (thrown) {
                if (axios.isCancel) { console.warn("Promise Aborted"); }
            });
    }, [bmpEndpoint, finalFilter, auth, controllerRef]);

    const onFilterChanged = useCallback((filterFromComponent: ManageProjectFilter) => {
        if (!filterFromComponent || selectedJurisdictionId === -1) {
            return;
        }
        setFilter(filterFromComponent);
    }, [selectedJurisdictionId]);

    useEffect(() => {
        if (selectedJurisdictionId !== -1) {
            setFetchingQueueItemList(true);
            generateLayerList();
        }
    }, [selectedJurisdictionId]);

    const onInfoClick = async (bmpId: number, viewType: string) => {
        let fullProject = await GetById<BmpGeneral>(
            BmpGeneral.endpoint,
            auth.user?.access_token,
            bmpId);

        props.setSidebarProjectInfoObj({ ...fullProject });
        props.dispatchSidebar({
            type: "open",
            payload: { name: viewType, content: { showEditButton: true } },
        });
    };

    useEffect(() => {
        if (queueItemList.length > 0) {
            const sortedQueueItemList = queueItemList.sort((a, b) => b.created.valueOf() < a.created.valueOf() ? -1 : 1);
            setSortedQueueItemList(sortedQueueItemList);
        } else {
            setSortedQueueItemList(new Array<QueueItemModel>());
        }
    }, [queueItemList]);

    useEffect(() => {
        if (selectedJurisdictionId < 0) {
            return;
        }
        setIsLoading(true);
        var selectedJurisdiction = props.lookups.lutJurisdiction.find(j => j.id === selectedJurisdictionId);
        var finalFilterTemp = {
            ...filter,
            jurisdictions: [selectedJurisdiction.internalName],
        };

        setFinalFilter(finalFilterTemp);
    }, [filter, props.lookups, selectedJurisdictionId]);

    useEffect(() => {
        getData();
    }, [finalFilter]);

    useEffect(() => {
        if (props.refreshTable === true) {
            getData();
        }
    }, [props.refreshTable]);

    useEffect(() => {
        if (props.lookups?.lutBmpClass?.length > 0) {
            const nrClassId = props.lookups.getBmpClassByInternalName("newAndRedevelopment");

            setNewRedevelopmentAvailable(bmpGeneral.some(bmp => bmp.bmpClassId === nrClassId));
            setWcmAvailable(bmpGeneral.some(bmp => bmp.bmpClassId !== nrClassId));
        }
    }, [bmpGeneral, props.lookups]);

    const downloadWcm = async () => {
        const endpoint = Config.api_endpoint + "bmp/wcmbulkdownload?" + qs.stringify(finalFilter);

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "Watershed Control Measures.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }

    const downloadNewRedevelopment = async () => {
        const endpoint = Config.api_endpoint + "bmp/newredevelopmentdownload?" + qs.stringify(finalFilter);

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "New & Redevelopment.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    };

    const downloadTemplateWcm = async () => {
        const endpoint = Config.api_endpoint + "BulkUploadDocument/WatershedControlMeasureTemplate";

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "watershed_control_measures_bulk_upload.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }

    const downloadTemplateNar = async () => {
        const endpoint = Config.api_endpoint + "BulkUploadDocument/NewRedevelopmentTemplate";

        let toastId = toast.info("Downloading...", { containerId: "manage-project-data-toast-container" });
        try {
            await Download(endpoint, auth.user?.access_token, "new_redevelopment_bulk_upload.xlsx");
        } catch (err) {
            toast.error("Failed to download file: " + err, { containerId: "manage-project-data-toast-container" });
        } finally {
            toast.dismiss(toastId);
        }
    }

    const sidebarFunc = async (feature: any) => {
        if (feature && feature.id && feature.id >= 0) {
            await onInfoClick(feature.id, "PrettyProjectPage");
        }
    }

    const geoJsonClick = useCallback(async (e: any) => {
        // Open PrettyProjectPage will eventually be here
        let fullProject = await GetById<BmpGeneral>(
            BmpGeneral.endpoint,
            auth.user?.access_token,
            e.results[0]?.graphic.attributes.bmpGeneralId
        )

        props.setSidebarProjectInfoObj({ ...fullProject });
        props.dispatchSidebar({
            type: "open",
            payload: { name: "PrettyProjectPage", content: { showEditButton: true } },
        });
    }, [props, globalAuthInfoContext]);

    const [layers, setLayers] = useState<Layer[]>();

    const sublayers = useMemo(() => {
        return [
            { name: "Safe Clean Water Program Watershed Areas", viz: true },
            { name: "Jurisdictions", viz: true },
            { name: "Watershed Management Groups (WMGs)", viz: true },
            { name: "Subwatersheds", viz: false },
            { name: "Major Watersheds", viz: false },
            { name: "Channels(Drainage)", viz: true },
            { name: "Storm Drains", viz: false }
        ];
    }, [])

    const generateLayerList = () => {
        setLayers([
            ...sublayers.map((sublayer, i) => {
                return new WMS(
                    i.toString(),
                    sublayer.name,
                    Config.map_connection_url,
                    sublayer.viz,
                    [
                        sublayer.name
                    ],
                );
            }),
            new GeoJSON(
                "New & Redevelopment Projects",
                "New and Redevelopment",
                `${Config.api_endpoint}GeoJson/GetMapBmpData/${selectedJurisdictionId}/1005`,
                true,
                false,
                {
                    type: "simple",
                    symbol: {
                        type: "picture-marker",
                        url: newRedev,
                        width: "15px",
                        height: "15px"
                    }
                },
                geoJsonClick,
                null,
                null,
                { Authorization: "Bearer " + auth.user?.access_token }
            ),
            new GeoJSON(
                "inProgressWCMs",
                "In-Progress WCMs",
                `${Config.api_endpoint}GeoJson/GetMapBmpData/${selectedJurisdictionId}/1003/1000`,
                true,
                true,
                {
                    type: "simple",
                    symbol: {
                        type: "picture-marker",
                        url: inProgressWCM,
                        width: "38px",
                        height: "38px"
                    }
                },
                geoJsonClick,
                null,
                null,
                { Authorization: "Bearer " + auth.user?.access_token }
            ),
            new GeoJSON(
                "completedWCMs",
                "Completed WCMs",
                `${Config.api_endpoint}GeoJson/GetMapBmpData/${selectedJurisdictionId}/1003/1001`,
                true,
                true,
                { //renderer
                    type: "simple",
                    symbol: {
                        type: "picture-marker",
                        url: CompleteWCMMarker,
                        width: "38px",
                        height: "38px"
                    }
                },
                geoJsonClick,
                null,
                null,
                { Authorization: "Bearer " + auth.user?.access_token }
            )
        ])
    }

    const toggleLayerVisibility = useCallback((layer: Layer) => {
        layers
            .filter(l => l === layer)
            .forEach(l => l.visible = !l.visible);
        setLayers([...layers]);
    }, [layers, setLayers]);

    const [extent, setExtent] = useState<Extent>();
    useEffect(() => {
        (async function () {
            var extent = await geoJsonService.getExtentForJurisdiction(selectedJurisdictionId);
            if (extent.ymin === -extent.ymax) {
                return;
            }
            setExtent(extent);
        })();

    }, [selectedJurisdictionId])

    return (
        <div className="manage-project-data-page">
            <Translation>
                {
                    (_) => (
                        <GlobalAuthInfoContext.Consumer>
                            {
                                (authInfo: IGlobalAuthInfo) => {
                                    return (

                                        <>
                                            <ToastContainer enableMultiContainer containerId={"manage-project-data-toast-container"} position="top-center" hideProgressBar={true} autoClose={false} />
                                            <div className="content-div">
                                                <div className="jurisdiction-dropdown-wrapper pb-2">
                                                    <DropdownLutH2O
                                                        dropdownItems={authInfo.jurisdictionList !== undefined ? authInfo.jurisdictionList : []}
                                                        dropdownTitle={"Jurisdiction"}
                                                        isMultiselect={false}
                                                        nullLabel={null}
                                                        onSelect={(e, d) => { setSelectedJurisdictionId(d.value) }}
                                                        search={true}
                                                        parentId={selectedJurisdictionId}
                                                    />
                                                </div>
                                                <div className="jurisdiction-title jurisdiction-div">
                                                    {props.lookups
                                                        ? props.lookups.getJurisdictionById(
                                                            selectedJurisdictionId
                                                        )
                                                        : ""}
                                                    <hr />
                                                </div>
                                                <div className="sub-header">Manage Project Data</div>
                                                <div className="menu-items">
                                                    <Menu pointing secondary>
                                                        <Menu.Item
                                                            name="Uploaded Project List"
                                                            active={activeItem === "Uploaded Project List"}
                                                            onClick={() => setActiveItem("Uploaded Project List")}
                                                            content="Uploaded Projects"
                                                            style={{ color: "#ff0000" }}
                                                        />
                                                        <Menu.Item
                                                            name="Bulk Uploads"
                                                            active={activeItem === "Bulk Uploads"}
                                                            onClick={() => setActiveItem("Bulk Uploads")}
                                                            content="Upload Project Data"
                                                        />
                                                        <Menu.Item
                                                            name="Project Map"
                                                            active={activeItem === "Project Map"}
                                                            onClick={() => setActiveItem("Project Map")}
                                                            content="Map"
                                                        />
                                                    </Menu>
                                                </div>
                                                <div className="manage-project-data-container">
                                                    {/* UPLOADED PROJECTS TAB */}
                                                    {
                                                        activeItem === "Uploaded Project List" &&
                                                        <>
                                                            <FilterComponent
                                                                currentJurisdictionId={selectedJurisdictionId}
                                                                onChange={onFilterChanged}
                                                                lookups={props.lookups} />
                                                            <div className="hr-line"></div>
                                                            <div className="sub-header2">
                                                                Uploaded Project List
                                                            </div>
                                                            {!(isLoading)
                                                                ? <div className="sub-header3">{bmpGeneral.length} projects loaded.</div>
                                                                : <div className="sub-header3">. . .</div>
                                                            }
                                                            <Segment>
                                                                <Dimmer inverted active={isLoading}>
                                                                    <Loader active={isLoading} />
                                                                </Dimmer>
                                                                <div className="download-header">
                                                                    <div className="spacer-div"></div>
                                                                    <div className="spacer-div pb-1">
                                                                        {
                                                                            wcmAvailable &&
                                                                            <Button
                                                                                className="primaryBtn mr-1"
                                                                                onClick={downloadWcm}
                                                                                content="Bulk Download Watershed Control Measures"
                                                                            />
                                                                        }
                                                                        {
                                                                            newRedevelopmentAvailable &&
                                                                            <Button
                                                                                className="primaryBtn ml-1"
                                                                                onClick={downloadNewRedevelopment}
                                                                                content="Bulk Download New &amp; Redevelopment Projects"
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="spacer-div align-right">
                                                                        {
                                                                            checkedSet.size > 0 &&
                                                                            <div className="delete-icon">
                                                                                <span>Delete Selected Items</span>
                                                                                <ConfirmDeleteModal theme={'red'} icon='trash alternate outline' confirmDeleteFunc={deleteSelected} itemToDelete={checkedSet} displayName={`${checkedSet.size} item` + (checkedSet.size > 1 ? `s` : ``)} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <ProjectList
                                                                    lookups={props.lookups}
                                                                    bmpGeneral={bmpGeneral}
                                                                    onInfoClick={onInfoClick}
                                                                    editFunctions={{ updateSelection, updateSelectionAll, checkedSet }}
                                                                    publicOrInternal="internal"
                                                                    prettyProjectButtonTitle="Summary"
                                                                />
                                                            </Segment>
                                                        </>
                                                    }
                                                    {/* UPLOAD PROJECT DATA TAB */}
                                                    {
                                                        activeItem === "Bulk Uploads" &&
                                                        <>
                                                            <div className="queue-btn-wrapper">
                                                                {/* UPLOADS PROJECTS BUTTON */}
                                                                <div className="upload-button-and-instructions">
                                                                    <ExcelUploadModal
                                                                        theme={"secondaryBtn"}
                                                                        fileUploaded={fileUploaded}
                                                                        setFileUploaded={setFileUploaded}
                                                                        attachmentModel={QueueRequest}
                                                                        btnLabel="&nbsp;&nbsp;UPLOAD PROJECTS"
                                                                        reFetchListFunc={setFetchingQueueItemList}
                                                                        selectedJurisdictionId={selectedJurisdictionId}
                                                                    />
                                                                    <TooltipModal />
                                                                </div>
                                                                {/* WSCM TEMPLATE BUTTON */}
                                                                <ButtonComponent
                                                                    onBtnClick={downloadTemplateWcm}
                                                                    label="Download Watershed Control Measures Template"
                                                                    theme="primaryBtn ml-1"
                                                                />
                                                                {/* NEW & REDEV TEMPLATE BUTTON */}
                                                                <ButtonComponent
                                                                    onBtnClick={downloadTemplateNar}
                                                                    label="Download New &amp; Redevelopment Template"
                                                                    theme="primaryBtn ml-1"
                                                                />
                                                                {/* REFRESH TABLE BUTTON */}
                                                                <ButtonComponent
                                                                    onBtnClick={() => setFetchingQueueItemList(true)}
                                                                    label="Refresh Table"
                                                                    disabled={fetchingQueueItemList}
                                                                    loading={fetchingQueueItemList}
                                                                    theme={"primaryBtn"}
                                                                />
                                                            </div>
                                                            <div className="sub-header2">
                                                                Recent Bulk Uploads <br />
                                                                <div className="note">(Failed uploads can be archived from table)</div>
                                                            </div>

                                                            <Table celled fixed >
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width="2">File Name</Table.HeaderCell>
                                                                        <Table.HeaderCell width="2">Upload Date</Table.HeaderCell>
                                                                        <Table.HeaderCell width="1">Step</Table.HeaderCell>
                                                                        <Table.HeaderCell width="1">Status</Table.HeaderCell>
                                                                        <Table.HeaderCell width="1">Projects Uploaded Count</Table.HeaderCell>
                                                                        <Table.HeaderCell width="1" className="center">Error(s) / Warning(s)</Table.HeaderCell>
                                                                        <Table.HeaderCell width="2">Finish Date</Table.HeaderCell>
                                                                        <Table.HeaderCell width="1" className="center">Archive</Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                    {
                                                                        // newest items first
                                                                        sortedQueueItemList.map((item: QueueItemModel, index: number) => {
                                                                            return (<Table.Row key={index + item.fileName}>
                                                                                <Table.Cell>{item.fileName}</Table.Cell>
                                                                                {/* Upload Date */}
                                                                                <Table.Cell>{new Date(item.created).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</Table.Cell>
                                                                                <Table.Cell>{props.lookups ? props.lookups.getQueueStepProcessById(item.queueStepProcessId) : "N/A"}</Table.Cell>
                                                                                <Table.Cell>{props.lookups ? props.lookups.getQueueStatusById(item.queueStatusId) : "N/A"}</Table.Cell>
                                                                                <Table.Cell>{(item.totalProjects && item.queueStatusId === props.lookups.getQueueStatusByInternalName("complete")) ? (item.totalProjects + " (" + item.duplicateCount + " Duplicates)") : "N/A"}</Table.Cell>
                                                                                <Table.Cell className="center">
                                                                                    {(props.lookups.getQueueStatusById(item.queueStatusId) !== "Complete" || item.duplicateCount > 0 || item.warningCount > 0) &&
                                                                                        <QueueModal title="Edit Tool" queueItemId={item.id} theme={"primary"} icon={"info circle"} queueError={item.queueError} lookups={props.lookups} />
                                                                                    }
                                                                                </Table.Cell>
                                                                                <Table.Cell>{item.finishDate ? new Date(item.finishDate).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) : "N/A"}</Table.Cell>
                                                                                <Table.Cell className="center">
                                                                                    {
                                                                                        props.lookups && item.queueStatusId === 1003 ?
                                                                                            <ConfirmArchiveModal theme={'blue'} icon='trash' displayName={item.fileName} confirmArchiveFunc={deleteBulkUpload} itemToArchive={item.id} />
                                                                                            : ""
                                                                                    }
                                                                                </Table.Cell>
                                                                            </Table.Row>)
                                                                        })
                                                                    }
                                                                </Table.Body>
                                                            </Table>
                                                        </>
                                                    }
                                                    {/* MAP TAB */}
                                                    {
                                                        activeItem === "Project Map" &&
                                                        <div className="manage-proj-data-map-div">
                                                            <div className="map-box">
                                                                <div className="map" style={{ height: "800px", width: "100%" }}>
                                                                    <Map {...MpdMapConfig} layers={[...layers]} onMapViewCreated={() => {}} extent={extent} zoomWidgetPosition="top-left" legend={(layers: Array<Layer>) => <Legend layers={layers} toggleVisibility={toggleLayerVisibility} topUnhiddenLayers={3} />} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        </GlobalAuthInfoContext.Consumer>
                    )}
            </Translation>
        </div >
    );
};

