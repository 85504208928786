import { Translation } from "react-i18next";
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";
import BmpGeneralCobenefits from "../../../models/BmpGeneralCobenefits";
import { ToggleComponent } from "../../../paradigmLib/domElements/toggleComponent/ToggleComponent";
import './ProjectEditPages.scss';

export const ProjCobenefits: React.FC<IProjectEditPageProps> = (props: IProjectEditPageProps) => {
    const setCobenefits = (newBenefits: BmpGeneralCobenefits) => {
        props.setProject({
            ...props.project,
            bmpGeneralCobenefits: newBenefits
        });
    };

    return (
        <div className="edit-proj-benefits pt-point5">
            <Translation>
                {
                    t =>
                        <>
                            {props.project && props.project.bmpClassId &&
                                (props.project.bmpClassId === props.lookups.getBmpClassByInternalName("watershedControlMeasure")
                                    || props.project.bmpClassId === props.lookups.getBmpClassByInternalName("nonStructuralControlMeasure")) &&
                                <>
                                    <ToggleComponent
                                        sideLabel="Provides Clean Streets Benefit (e.g., street sweeping, litter abatement, etc)?"
                                        value={props.project.bmpGeneralCobenefits.coBenefitCleanStreets === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitCleanStreets = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                    <ToggleComponent
                                        sideLabel="Provides More Parks and Green Spaces Benefit?"
                                        value={props.project.bmpGeneralCobenefits.coBenefitParks === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitParks = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                    <ToggleComponent
                                        sideLabel="Provides Reduced Heat Island Effect Benefit?"
                                        value={props.project.bmpGeneralCobenefits.coBenefitHeatIsland === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitHeatIsland = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                    <ToggleComponent
                                        sideLabel="Provides Reduced Flooding Benefit?"
                                        value={props.project.bmpGeneralCobenefits.coBenefitFlooding === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitFlooding = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                    <ToggleComponent
                                        sideLabel="Provides Water Supply Augmentation Benefit?"
                                        value={props.project.bmpGeneralCobenefits.coBenefitWaterSupplyAugmentation === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitWaterSupplyAugmentation = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                    <ToggleComponent
                                        sideLabel="Provides Neighborhood Beautification Benefit?"
                                        value={props.project.bmpGeneralCobenefits.coBenefitNeighborhood === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitNeighborhood = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                    <ToggleComponent
                                        sideLabel="Provides Job Creation Benefit?"
                                        value={props.project.bmpGeneralCobenefits.coBenefitJobCreation === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitJobCreation = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                    <ToggleComponent
                                        sideLabel="Provides Benefits Accruing to Disadvantaged Communities? (as identified on CalEnviroScreen)"
                                        value={props.project.bmpGeneralCobenefits.coBenefitDisadvantaged === 1}
                                        setValue={(newValue: boolean) => {
                                            const benefits = props.project.bmpGeneralCobenefits;
                                            benefits.coBenefitDisadvantaged = newValue ? 1 : 0;
                                            setCobenefits(benefits);
                                        }}
                                        theme={"primary"}
                                    />
                                </>
                            }
                        </>
                }
            </Translation>
        </div >
    )
}
