import { useAuth } from 'react-oidc-context';
import './authLoginComponent.scss';

export class AuthLoginProps {
    loginLabel: string;
    logoutLabel: string;
}

export const AuthLoginComponent: any = (props: AuthLoginProps) => {
    // const { oidcUser } = useReactOidc();
    // const { profile } = oidcUser;

    var auth = useAuth();

    const getUserInfo = (urs: any) => {
        if (urs && urs.oidcUser) {
            //let roles = urs.oidcUser.profile["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
            //console.log('user profile', urs.oidcUser.profile, roles, urs.oidcUser)
            // console.log('auth', urs.oidcUser);
        }
        //return urs.oidcUser.profile.name;
        return true;
    }

    return (
        <div className='auth-login-component'>
            {
                auth.user && getUserInfo(auth) ?
                    (
                        <div className="" onClick={() => auth.signoutRedirect()}>{props.logoutLabel}</div>
                        // </ul>
                    )
                    :
                    (
                        <div className="nav-menu-show expand" onClick={() => auth.signinRedirect()}>{props.loginLabel}</div>
                    )
            }
        </div >
    )
}
