import MapPopup from "../../mapPopup/MapPopup";
import Point from "@arcgis/core/geometry/Point";

const MpdMapConfig = {
    basemapUrl: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}", //TODO: Change me to Config.map_connection_url but cant get basemap yet
    copyright: "Tiles courtesy of the <a href='https://usgs.gov/'>U.S. Geological Survey</a>", //TODO: Change me when above changes
    popup: (feature: any) => <MapPopup feature={feature} />,
    zoom: 10,
    center: new Point({ longitude: -118.30, latitude: 34.130 }),
    zoomWidgetPosition: "top-right",
};

export default MpdMapConfig;
