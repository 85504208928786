import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Menu } from "semantic-ui-react";
import { Translation } from "react-i18next";
import WelcomeViewModel from "../../models/viewModels/WelcomeViewModel";
import { useAPIFetchAllLocal } from "../../core/UseAPILocal";
import WatershedManagementGroupIsPublic from "../../models/WatershedManagementGroupIsPublic";
import { Get } from "../../core/DownloadUtil";
import { WelcomePageSidebar } from "./welcomePageSidebar/WelcomePageSidebar";
import { ProgressReporting } from "./ProgressReporting";
import LookupFactory from "../../lookups/LookupFactory";
import BmpGeneral from "../../models/BmpGeneral";
import { Participants } from "./Participants";
import { CoBenefits } from "./CoBenefits";
import './WelcomePage.scss';

interface IWelcomePageProps {
    visible: boolean;
    setVisible: Function;
    lookups: LookupFactory;
    sidebarProjectInfoObj: BmpGeneral;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
}

export const WelcomePage = (props: IWelcomePageProps) => {
    const [activeItem, setActiveItem] = useState<string>('wsGroups');
    const [watershedId, setWatershedId] = useState<number>();
    const [welcomeInfo, setWelcomeInfo] = useState<WelcomeViewModel>(new WelcomeViewModel());
    const [publicReports, setPublicReports] = useState(false);
    const [publicDashboard, setPublicDashboard] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);

    const [watershedManagementGroupIsPublic, setWatershedManagementGroupIsPublic] = useState<WatershedManagementGroupIsPublic[]>(new Array<WatershedManagementGroupIsPublic>());
    const [fetchWatershedManagementGroupIsPublic, setFetchWatershedManagementGroupIsPublic] = useState<boolean>(true);

    useEffect(() => {
        Get<WelcomeViewModel>(WelcomeViewModel.endpoint, null)
            .then((response) => {
                setWelcomeInfo(response);
            });
    }, []);

    const handleItemClick = (e, { name }) => {
        setActiveItem(name)
    };

    useAPIFetchAllLocal(
        "",
        new WatershedManagementGroupIsPublic(),
        fetchWatershedManagementGroupIsPublic,
        setFetchWatershedManagementGroupIsPublic,
        setWatershedManagementGroupIsPublic,
        { "active": 1 },
        (data: WatershedManagementGroupIsPublic[]) => {
        });

    return (
        <div className="welcome-page">
            <div className="welcome-page-inner-wrapper">
                <Translation>
                    {
                        t =>
                            <>
                                <div className="first-div">
                                    <div className="header-div">
                                        <h1 className="header">{t('welcomeHeader')}</h1>
                                    </div>

                                    <div className="main-text-div">
                                        <div className="content-divs">{t('welcomeText1')}</div>
                                        <div className="content-divs mt-point5">{t('welcomeText2')}</div>
                                    </div>

                                    <div className="white-wave-wrapper">
                                        <div className="white-wave"></div>
                                    </div>
                                </div>

                                <div className="small-device-message">Please visit this page from a larger device</div>

                                <div className="tab-div">
                                    <div className="menu-div">
                                        <Menu pointing secondary>
                                            <Menu.Item
                                                name='wsGroups'
                                                active={activeItem === 'wsGroups'}
                                                onClick={handleItemClick}
                                                content="Participants"
                                            />
                                            <Menu.Item
                                                name='cobenefits'
                                                active={activeItem === 'cobenefits'}
                                                onClick={handleItemClick}
                                                content="Co-Benefits"
                                            />
                                            {(activeItem === "forms") &&
                                                <Menu.Item
                                                    name='forms'
                                                    active={activeItem === 'forms'}
                                                    onClick={handleItemClick}
                                                    content="Progress Reporting"
                                                />
                                            }
                                        </Menu>
                                    </div>

                                    {/* Participants tab */}
                                    {activeItem === "wsGroups" &&
                                        <div className="first-tab">
                                            <Participants
                                                lookups={props.lookups}
                                                setWatershedId={setWatershedId}
                                                setActiveItem={setActiveItem}
                                                publicReports={publicReports}
                                                setPublicReports={setPublicReports}
                                                publicDashboard={publicDashboard}
                                                watershedManagementGroupIsPublic={watershedManagementGroupIsPublic}
                                                setPublicDashboard={setPublicDashboard} />
                                        </div>
                                    }

                                    {/* Co-Benefits tab */}
                                    {activeItem === "cobenefits" &&
                                        <div className="second-tab">
                                            <CoBenefits welcomeInfo={welcomeInfo} />
                                        </div>
                                    }

                                    {/* Progress Reporting tab */}
                                    {activeItem === "forms" &&
                                        <div className="fourth-tab">
                                            <ProgressReporting
                                                lookups={props.lookups}
                                                wsmgId={watershedId}
                                                sidebarVisible={sidebarVisible}
                                                setSidebarVisible={setSidebarVisible}
                                                sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                                                setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                                publicReports={publicReports}
                                                publicDashboard={publicDashboard}
                                            />
                                        </div>
                                    }
                                </div>
                            </>
                    }
                </Translation>
                <WelcomePageSidebar
                    sidebarVisible={sidebarVisible}
                    setSidebarVisible={setSidebarVisible}
                    lookups={props.lookups}
                    sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                />
                <div className="footer-wave-wrapper">
                    <div className="blue-wave">
                        <div className="wave-background"></div>
                        <div className="wave-filler"></div>
                    </div>
                </div>
            </div >
        </div>
    )
}
