import { Translation } from "react-i18next";
import { Divider, Icon } from "semantic-ui-react";
import LookupFactory from "../../lookups/LookupFactory";
import WatershedManagementGroup from "../../models/WatershedManagementGroup";
import WatershedManagementGroupIsPublic from "../../models/WatershedManagementGroupIsPublic";
import "./Participants.scss";

interface IParticipantsProps {
    lookups: LookupFactory;
    setWatershedId: Function;
    watershedManagementGroupIsPublic: WatershedManagementGroupIsPublic[];
    setActiveItem: Function;
    publicReports: boolean;
    setPublicReports: Function;
    publicDashboard: boolean;
    setPublicDashboard: Function;
}

export const Participants = (props: IParticipantsProps) => {

    const handleIconClick = (watershedManagementGroupItem: WatershedManagementGroupIsPublic, id: number) => {
        props.setWatershedId(id);
        props.setActiveItem("forms");
        if (watershedManagementGroupItem.isPublicReports === 1) {
            props.setPublicReports(true);
        } else {
            props.setPublicReports(false);
        }
        if (watershedManagementGroupItem.isPublicDashboard === 1) {
            props.setPublicDashboard(true);
        } else {
            props.setPublicDashboard(false);
        }
    };

    return (
        <Translation>
            {
                t =>
                    <>
                        <div className="participants">
                            <div className="participants-header">
                                <div className="list-title">Watershed Groups Progress Reporting</div>
                                <Divider />
                                <div className="list-subtitle">
                                    The Watershed Groups below are using WRAMPS to make their WMP Progress Reports readily accessible to and easily understandable for the public. Please explore each Watershed Group's progress using the links below to learn more about their progress toward water quality milestones and co-benefits provided.
                                </div>
                            </div>
                            <div className="wsgroup-table-container">
                                <table>
                                    <tbody>
                                        <tr className="header-row">
                                            <td width='2' className="header first-column">Watershed Groups</td>
                                            <td width='2' className="header center">Progress Reporting</td>
                                        </tr>
                                        <tr className="row-spacer"></tr>
                                        <tr className="row-spacer"></tr>
                                        {props.lookups && props.lookups &&
                                            props.lookups.lutWatershedManagementGroup.map((wsObj: WatershedManagementGroup, index: number) => {
                                                let watershedManagementGroupItem = props.watershedManagementGroupIsPublic.find(x => x.watershedGroupId === wsObj.id);
                                                if (watershedManagementGroupItem?.isPublicReports === 1 ||
                                                    watershedManagementGroupItem?.isPublicDashboard === 1) {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="first-column">{wsObj.displayName}</td>
                                                            <td className="center">
                                                                <Icon
                                                                    id={wsObj.id}
                                                                    className="icon"
                                                                    name="pie graph"
                                                                    onClick={() => handleIconClick(watershedManagementGroupItem, wsObj.id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {
                                props.watershedManagementGroupIsPublic &&
                                    props.watershedManagementGroupIsPublic.filter(x => x.isPublicReports === 1).length === 0
                                    ?
                                    <div className="no-watersheds">None of the participating Watershed Groups have published the progress report forms
                                        <br /><br />
                                        Please check back after the reporting deadline (June 15 or December 15)  </div>
                                    :
                                    ""
                            }
                        </div>
                    </>
            }
        </Translation >
    )
}