import Jurisdiction from "../models/Jurisdiction"
import JurisdictionGroup from "../models/JurisdictionGroup"
import LutAnnualReportingPeriod from "../models/lutModels/LutAnnualReportingPeriod"
import LutBmpClass from "../models/lutModels/LutBmpClass"
import LutBmpStatus from "../models/lutModels/LutBmpStatus"
import LutBmpStatusIncomplete from "../models/lutModels/LutBmpStatusIncomplete"
import LutBmpStatusType from "../models/lutModels/LutBmpStatusType"
import LutBmpTechnicalAssistance from "../models/lutModels/LutBmpTechnicalAssistance"
import LutBmpType from "../models/lutModels/LutBmpType"
import LutDrainageLanduse from "../models/lutModels/LutDrainageLanduse"
import LutFundingSource from "../models/lutModels/LutFundingSource"
import LutFundingType from "../models/lutModels/LutFundingType"
import LutLanguage from "../models/lutModels/LutLanguage"
import LutLidDevelopmentType from "../models/lutModels/LutLidDevelopmentType"
import LutQueueErrorType from "../models/lutModels/LutQueueErrorType"
import LutQueueStatus from "../models/lutModels/LutQueueStatus"
import LutQueueStepProcess from "../models/lutModels/LutQueueStepProcess"
import LutWatershedGroupOutputType from "../models/lutModels/LutWatershedGroupOutputType"
import LutWatershedReportingPeriod from "../models/lutModels/LutWatershedReportingPeriod"
import LutWatershedReportStatus from "../models/lutModels/LutWatershedReportStatus"
import ReceivingWaters from "../models/lutModels/ReceivingWaters"
import Watershed from "../models/lutModels/Watershed"
import WatershedManagementGroup from "../models/lutModels/WatershedManagementGroup"
import SCWshed from "../models/SCWshed"
import WeatherStation from "../models/WeatherStation"

export default class LookupFactory {

    lutAnnualReportingPeriod: LutAnnualReportingPeriod[] = [];

    lutBmpClass: LutBmpClass[] = [];

    lutBmpStatus: LutBmpStatus[] = [];

    lutBmpStatusType: LutBmpStatusType[] = [];

    lutBmpStatusIncomplete: LutBmpStatusIncomplete[] = [];

    lutBmpType: LutBmpType[] = [];

    lutDrainageLanduse: LutDrainageLanduse[] = [];

    lutFundingSource: LutFundingSource[] = [];

    lutFundingType: LutFundingType[] = [];

    lutReceivingWaters: ReceivingWaters[] = [];

    lutWatershed: Watershed[] = [];

    lutWatershedManagementGroup: WatershedManagementGroup[] = [];

    lutJurisdiction: Jurisdiction[] = [];

    lutWatershedReportStatus: LutWatershedReportStatus[] = [];

    lutWatershedReportingPeriod: LutWatershedReportingPeriod[] = [];

    lutQueueStatus: LutQueueStatus[] = [];

    lutQueueStepProcess: LutQueueStepProcess[] = [];

    lutQueueErrorType: LutQueueErrorType[] = [];

    lutBmpTechnicalAssistance: LutBmpTechnicalAssistance[] = [];

    lutLidDevelopmentType: LutLidDevelopmentType[] = [];

    lutWeatherStation: WeatherStation[] = [];

    lutSCWshed: SCWshed[] = [];

    lutWatershedGroupOutputType: LutWatershedGroupOutputType[] = [];

    lutLanguage: LutLanguage[] = [];

    jurisdictionGroups: JurisdictionGroup[] = [];
    

    getQueueStatusById = (id: number): string => {
        return this.lutQueueStatus.find(_ => _.id === id)?.displayName || ""
    }

    getQueueStatusByInternalName = (name: string): number => {
        return this.lutQueueStatus.find((type: LutQueueStatus) => { return type.internalName === name })?.id;
    }

    getQueueStatusByName = (name: string): number => {
        return this.lutQueueStatus.find((type: LutQueueStatus) => { return type.displayName === name })?.id;
    }

    getQueueStepProcessById = (id: number): string => {
        return this.lutQueueStepProcess.find(_ => _.id === id)?.displayName || ""
    }

    getQueueStepProcessByName = (name: string): number => {
        return this.lutQueueStepProcess.find((type: LutQueueErrorType) => { return type.displayName === name })?.id;
    }

    getQueueErrorTypeById = (id: number): string => {
        return this.lutQueueErrorType.find(_ => _.id === id)?.displayName || ""
    }

    getQueueErrorTypeByName = (name: string): number => {
        return this.lutQueueErrorType.find((type: LutQueueErrorType) => { return type.displayName === name })?.id;
    }
    getWatershedReportStatusById = (id: number): string => {
        return this.lutWatershedReportStatus.find(_ => _.id === id)?.displayName || ""
    }

    getWatershedReportStatusInternalNameById = (id: number): string => {
        return this.lutWatershedReportStatus.find(_ => _.id === id)?.internalName || ""
    }

    getWatershedReportStatusByName = (name: string): number => {
        return this.lutWatershedReportStatus.find((type: LutWatershedReportStatus) => { return type.displayName === name })?.id;
    }

    getBmpClassById = (id: number): string => {
        return this.lutBmpClass.find(_ => _.id === id)?.displayName || ""
    }

    getBmpClassByName = (name: string): number => {
        return this.lutBmpClass.find((type: LutBmpClass) => { return type.displayName === name })?.id;
    }

    getBmpClassByInternalName = (name: string): number => {
        return this.lutBmpClass.find((type: LutBmpClass) => { return type.internalName === name })?.id;
    }

    getBmpStatusById = (id: number): string => {
        return this.lutBmpStatus.find(_ => _.id === id)?.displayName || ""
    }

    getBmpStatusByName = (name: string): number => {
        return this.lutBmpStatus.find((type: LutBmpStatus) => { return type.displayName === name })?.id;
    }

    getBmpStatusIncompleteById = (id: number): string => {
        return this.lutBmpStatus.find(_ => _.id === id)?.displayName || ""
    }

    getBmpStatusIncompleteByName = (name: string): number => {
        return this.lutBmpStatusIncomplete.find((type: LutBmpStatusIncomplete) => { return type.displayName === name })?.id;
    }

    getBmpTypeById = (id: number): string => {
        return this.lutBmpType.find(_ => _.id === id)?.displayName || ""
    }

    getBmpTypeByName = (name: string): number => {
        return this.lutBmpType.find((type: LutBmpType) => { return type.displayName === name })?.id;
    }

    getBmpTypeByInternalName = (name: string): number => {
        return this.lutBmpType.find((type: LutBmpType) => { return type.internalName === name })?.id;
    }

    getDrainageLanduseById = (id: number): string => {
        return this.lutDrainageLanduse.find(_ => _.id === id)?.displayName || ""
    }

    getDrainageLanduseByName = (name: string): number => {
        return this.lutDrainageLanduse.find((type: LutDrainageLanduse) => { return type.displayName === name })?.id;
    }

    getFundingSourceById = (id: number): string => {
        return this.lutFundingSource.find(_ => _.id === id)?.displayName || ""
    }

    getFundingSourceByName = (name: string): number => {
        return this.lutFundingSource.find((type: LutFundingSource) => { return type.displayName === name })?.id;
    }

    getFundingTypeById = (id: number): string => {
        return this.lutFundingType.find(_ => _.id === id)?.displayName || ""
    }

    getFundingTypeByName = (name: string): number => {
        return this.lutFundingType.find((type: LutFundingType) => { return type.displayName === name })?.id;
    }

    getReceivingWatersById = (id: number): string => {
        return this.lutReceivingWaters.find(_ => _.id === id)?.displayName || ""
    }

    getReceivingWatersByName = (name: string): number => {
        return this.lutReceivingWaters.find((type: ReceivingWaters) => { return type.displayName === name })?.id;
    }

    getWatershedById = (id: number): string => {
        return this.lutWatershed.find(_ => _.id === id)?.displayName || ""
    }

    getWatershedByName = (name: string): number => {
        return this.lutWatershed.find((type: Watershed) => { return type.displayName === name })?.id;
    }

    getWatershedManagementGroupById = (id: number): string => {
        return this.lutWatershedManagementGroup.find(_ => _.id === id)?.displayName || ""
    }

    getWatershedManagementGroupByName = (name: string): number => {
        return this.lutWatershedManagementGroup.find((type: WatershedManagementGroup) => { return type.displayName === name })?.id;
    }

    getJurisdictionById = (id: number): string => {
        return this.lutJurisdiction.find(_ => _.id === id)?.displayName || ""
    }

    getJurisdictionByName = (name: string): number => {
        return this.lutJurisdiction.find((type: Jurisdiction) => { return type.displayName === name })?.id;
    }

    getReportingPeriodById = (id: number): string => {
        return this.lutWatershedReportingPeriod.find(_ => _.id === id)?.displayName || ""
    }

    getReportingPeriodByName = (name: string): number => {
        return this.lutWatershedReportingPeriod.find((type: LutWatershedReportingPeriod) => { return type.displayName === name })?.id;
    }

    getBmpTechnicalAssistanceById = (id: number): string => {
        return this.lutBmpTechnicalAssistance.find(_ => _.id === id)?.displayName || ""
    }

    getBmpTechnicalAssistanceByName = (name: string): number => {
        return this.lutBmpTechnicalAssistance.find((type: LutBmpTechnicalAssistance) => { return type.displayName === name })?.id;
    }

    getLidDevelopmentTypeById = (id: number): string => {
        return this.lutLidDevelopmentType.find(_ => _.id === id)?.displayName || ""
    }

    getLidDevelopmentTypeByName = (name: string): number => {
        return this.lutLidDevelopmentType.find((type: LutLidDevelopmentType) => { return type.displayName === name })?.id;
    }

    getWeatherStationById = (id: number): string => {
        return this.lutWeatherStation.find(_ => _.id === id)?.displayName || ""
    }

    getWeatherStationByName = (name: string): number => {
        return this.lutWeatherStation.find((type: WeatherStation) => { return type.displayName === name })?.id;
    }

    getSCWshedById = (id: number): string => {
        return this.lutSCWshed.find(_ => _.id === id)?.displayName || ""
    }

    getSCWshedByName = (name: string): number => {
        return this.lutSCWshed.find((type: SCWshed) => { return type.displayName === name })?.id;
    }

    getLutWatershedGroupOutputTypeById = (id: number): string => {
        return this.lutWatershedGroupOutputType.find(_ => _.id === id)?.displayName || ""
    }

    getLutWatershedGroupOutputTypeByName = (name: string): number => {
        return this.lutWatershedGroupOutputType.find((type: LutWatershedGroupOutputType) => { return type.displayName === name })?.id;
    }

    getLutLanguageTypeById = (id: number): string => {
        return this.lutLanguage.find(_ => _.id === id)?.displayNameEnglish || ""
    }

    getLutLanguageByName = (name: string): number => {
        return this.lutLanguage.find((type: LutLanguage) => { return type.displayNameEnglish === name })?.id;
    }

    getAnnualReportingPeriodById = (id: number): string => {
        return this.lutAnnualReportingPeriod.find(_ => _.id === id)?.displayName || ""
    }

}
