import React, { useEffect } from "react";
import { Translation } from "react-i18next";
import { ManageProjectData } from "../reporting/manageProjectData/ManageProjectData";
import { BuildWatershedForm } from "../reporting/buildWatershedForm/BuildWatershedForm";
import { BuildAnnReportForm } from "../reporting/buildAnnReportForm/BuildAnnReportForm";
import { ConfigureMilestones } from "../reporting/configureMilestones/ConfigureMilestones";
import { ConfigureProjAssumptions } from "../reporting/configureProjAssumptions/ConfigureProjAssumptions";
import LookupFactory from "../../lookups/LookupFactory";
import { UserAndInterestedPartyManager } from "../reporting/userAndInterestedPartyManager/UserAndInterestedPartyManager";
import BmpGeneral from "../../models/BmpGeneral";
import { PermissionsStatusComponent } from "../permissionsStatusComponent/PermissionsStatusComponent";
import './LandingPage.scss';

export interface ILandingPageProps {
    setSidebarProjectInfoObj?: Function;
    sidebarProjectInfoObj?: BmpGeneral;
    reportPageToShow: string
    setReportPageToShow: Function;
    dispatchSidebar: Function;
    visible: boolean;
    setVisible: Function;
    lookups: LookupFactory;
    refreshTable: boolean;
    setRefreshTable: Function;
}

export const LandingPage: React.FC<ILandingPageProps> = (props: ILandingPageProps) => {
    useEffect(() => {
        props.dispatchSidebar({
            type: "open",
            payload: { name: "Reports", content: null },
        });
    }, [])

    return (
        <div className="landing-page">

            <Translation>
                {
                    t =>
                        <PermissionsStatusComponent hasPermissions={props.reportPageToShow !== "alertMessagePage"}>
                            <>
                                {
                                    props.reportPageToShow === "manageProjectData" &&
                                    <ManageProjectData
                                        dispatchSidebar={props.dispatchSidebar}
                                        visible={props.visible}
                                        setVisible={props.setVisible}
                                        setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                        lookups={props.lookups}
                                        refreshTable={props.refreshTable}
                                        setRefreshTable={props.setRefreshTable} />
                                }
                                {
                                    props.reportPageToShow === "buildWatershedForm" &&
                                    <BuildWatershedForm dispatchSidebar={props.dispatchSidebar} setSidebarProjectInfoObj={props.setSidebarProjectInfoObj} set visible={props.visible} setVisible={props.setVisible} lookups={props.lookups} />
                                }
                                {
                                    props.reportPageToShow === "buildAnnReportForm" &&
                                    <BuildAnnReportForm dispatchSidebar={props.dispatchSidebar} visible={props.visible} setVisible={props.setVisible} lookups={props.lookups} />
                                }
                                {
                                    props.reportPageToShow === "configureMilestones" &&
                                    <ConfigureMilestones dispatchSidebar={props.dispatchSidebar} visible={props.visible} setVisible={props.setVisible} lookups={props.lookups} />
                                }
                                {
                                    props.reportPageToShow === "configureProjAssumptions" &&
                                    <ConfigureProjAssumptions dispatchSidebar={props.dispatchSidebar} visible={props.visible} setVisible={props.setVisible} lookups={props.lookups} />
                                }
                                {
                                    props.reportPageToShow === "userManagement" &&
                                    <UserAndInterestedPartyManager lookups={props.lookups} reportPageToShow={props.reportPageToShow} />
                                }
                            </>
                        </PermissionsStatusComponent>
                }
            </Translation>

        </div>
    )
}
