import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Translation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { useAPIFetchAllLocal } from "../../core/UseAPILocal";
import LookupFactory from "../../lookups/LookupFactory";
import BmpGeneral from "../../models/BmpGeneral";
import JurisdictionGroup from "../../models/JurisdictionGroup";
import { Dashboard } from "./Dashboard";
import { Downloads } from "./Downloads";
import "./ProgressReporting.scss";
import ReceivingWaters from "../../models/ReceivingWaters";

interface IPublicReportingProps {
    lookups: LookupFactory;
    wsmgId: number;
    sidebarVisible: boolean;
    setSidebarVisible: Dispatch<SetStateAction<boolean>>;
    sidebarProjectInfoObj: BmpGeneral;
    setSidebarProjectInfoObj: Dispatch<SetStateAction<BmpGeneral>>;
    publicReports: boolean;
    publicDashboard: boolean;
}

export const ProgressReporting = (props: IPublicReportingProps) => {
    const [jurisdictions, setJurisdictions] = useState<JurisdictionGroup[]>(new Array<JurisdictionGroup>());
    const [fetchJurisInfo, setFetchJurisInfo] = useState<boolean>(true);
    const [activeView, setActiveView] = useState<"downloads" | "dashboard" | "none">(props.publicReports ? "downloads" : (props.publicDashboard ? "dashboard" : "none"));

    //this fetches jurisdictions from the database that contains types JuridictionGroup
    useAPIFetchAllLocal("", new JurisdictionGroup(), fetchJurisInfo, setFetchJurisInfo, setJurisdictions, { 'watershedManagementGroupId': props.wsmgId, "active": 1 }, (data) => {
    });

    const receivingWaters: ReceivingWaters[] = useMemo(() => {
        return props.lookups.lutReceivingWaters
            .filter(rw => rw.wsGroupRWaterJurisdictionMapping
                .some(mapping => mapping.watershedManagementGroupId === props.wsmgId));
    }, [props.lookups.lutReceivingWaters, props.wsmgId])

    return (
        <Translation>
            {
                t =>
                    <>
                        <div className="progress-reporting">
                            <div className="top-section">
                                <div className="top-section-title">
                                    {props.lookups ?
                                        props.lookups.getWatershedManagementGroupById(props.wsmgId)
                                        : ""}
                                </div>
                                <div className="top-section-line"></div>
                                <div className="top-section-subtitle">
                                    This page is dedicated to the {props.lookups ?
                                        props.lookups.getWatershedManagementGroupById(props.wsmgId)
                                        : " "}
                                    &nbsp;Watershed Management Group and provides information on water quality improvement progress and resulting co-benefits.
                                </div>
                                <div className="city-watershed-wrapper">
                                    <div className="cities">
                                        <div className="cities-title">
                                            Permittees participating in this group are:
                                        </div>
                                        <div className="cities-text">
                                            {jurisdictions.map((item, index) => {
                                                return (
                                                    <div className="with-comma" key={item.id}>{props.lookups.getJurisdictionById(item.jurisdictionId)}{index < (jurisdictions.length - 1) ? ", " : ""}&nbsp;</div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="managed">
                                        <div className="managed-title">
                                            Subwatersheds managed by this group are:
                                        </div>
                                        <div className="managed-text">
                                            {receivingWaters.map((rw, index) => {
                                                return (
                                                    <div className="with-comma" key={index}>{rw.displayName}{index < (receivingWaters.length - 1) ? ", " : ""}&nbsp;</div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="button-group-section">
                                <Button.Group>
                                    {props.publicReports &&
                                        <Button
                                            className={activeView === "downloads" ? "active no-wrap" : "no-wrap"}
                                            onClick={() => setActiveView("downloads")}
                                        >
                                            Downloads
                                        </Button>
                                    }
                                    {props.publicDashboard &&
                                        <Button
                                            className={activeView === "dashboard" ? "active no-wrap" : "no-wrap"}
                                            onClick={() => setActiveView("dashboard")}
                                        >
                                            Dashboard
                                        </Button>
                                    }
                                </Button.Group>
                            </div>
                            <div className="downloads-dashboard-wrapper">
                                {activeView === "downloads" &&
                                    <Downloads
                                        lookups={props.lookups}
                                        wsmgId={props.wsmgId}
                                    />
                                }
                                {activeView === "dashboard" &&
                                    <Dashboard
                                        lookups={props.lookups}
                                        wsmgId={props.wsmgId}
                                        sidebarProjectInfoObj={props.sidebarProjectInfoObj}
                                        setSidebarProjectInfoObj={props.setSidebarProjectInfoObj}
                                        setSidebarVisible={props.setSidebarVisible}
                                    />
                                }
                                {activeView === "none" &&
                                    // Shouldnt be possible but just in case 
                                    <div className="label">No reports are available for this group</div>
                                }
                            </div>
                        </div>
                    </>
            }
        </Translation >
    )
}
