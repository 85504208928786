import { ResponsiveBar } from "@nivo/bar";
import { Translation } from "react-i18next";
import './BarChart.scss';

export const BarChart: any = (chartData: any) => {
    const colors = ['#88CCF1', '#ADD4EB', '#84C1D7'] // color of columns/bars
    //const getColor = bar => colors[bar.id]

    // console.log(chartData);

    return (
        <div className="bc">
            <Translation>
                 {
                    t =>
                     <>
                        <ResponsiveBar
                            data={chartData.chartData}
                            //data is being passed as an object (array is inside) and we can't figure out why. This gets it to work but I will come back and figure out permanent fix later.
                            keys={[
                                'impervious',
                                'pervious',
                                'total'
                            ]}
                            indexBy="acres"
                            margin={{ top: 20, right: 5, bottom: 20, left: 45 }}
                            padding={0.1}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={colors} // color of columns/bars
                            theme={{
                                    "background": "#ffffff",
                                    "textColor": "#333333",
                                    "fontSize": 11,
                                    "axis": {
                                        "domain": {
                                            "line": {
                                                "stroke": "#777777",
                                                "strokeWidth": 1
                                            }
                                        },
                                        "legend": {
                                            "text": {
                                                "fontSize": 13, //vertical label on left
                                                "fill": "#333333"
                                            }
                                        },
                                        "ticks": {
                                            "text": {
                                                "fontSize": 11, //labels on bottom and scale on left
                                                "fill": "#333333"
                                            }
                                        }
                                    },
                                    "tooltip": {
                                        "container": {
                                            "background": "#ffffff",
                                            "color": "#333333", // color of tooltip text on hover
                                            "fontSize": 11
                                        }
                                    }
                                }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 0,
                                tickRotation: 0,
                            }}
                            axisLeft={{
                                tickSize: 2,
                                tickPadding: 0,
                                tickRotation: 0,
                                legend: 'acres', // vertical label name displayed
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelTextColor="#ffffff" // color of label inside columns
                            role="application"
                        />
                    </>
                }
            </Translation>
        </div>
    )
}