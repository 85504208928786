import { useCallback } from "react";
import { Translation } from "react-i18next";
import { IProjectEditPageProps } from "../../sidebars/editProjectPage/EditProjectPage";
import BmpGeneralOAndM from "../../../models/BmpGeneralOAndM";
import { AddonInputComponent } from "../../../paradigmLib/domElements/addonInputComponent/AddonInputComponent";
import './ProjectEditPages.scss';

export const ProjOAndM: any = (props: IProjectEditPageProps) => {
    const setOAndM = useCallback((newOAndM: BmpGeneralOAndM) => {
        props.setProject({
            ...props.project,
            bmpGeneralOAndM: newOAndM
        });
    }, [props]);

    return (
        <div className="edit-proj-o-m pt-point5">
            <Translation>
                {
                    t =>
                        <>
                            {/* O & M */}

                            <AddonInputComponent
                                label="Estimated Annual O&amp;M Costs"
                                placeHolder="200000"
                                value={props.project.bmpGeneralOAndM.omEstimatedAnnual ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omEstimatedAnnual = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="Cumulative O&amp;M Costs through Reporting Period 1"
                                placeHolder="10000"
                                value={props.project.bmpGeneralOAndM.omEdtimatedCumulativeBeforePeriod1 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omEdtimatedCumulativeBeforePeriod1 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 1 (7/1/2021 - 12/31/2021)"
                                placeHolder="12000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod1 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod1 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 2 (1/1/2022 - 6/30/2022)"
                                placeHolder="13000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod2 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod2 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 3 (7/1/2022 - 12/31/2022)"
                                placeHolder="23000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod3 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod3 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 4 (1/1/2023 - 6/30/2023)"
                                placeHolder="40000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod4 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod4 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 5 (7/1/2023 - 12/31/2023)"
                                placeHolder="10000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod5 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod5 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 6 (1/1/2024 - 6/30/2024)"
                                placeHolder="12000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod6 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod6 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 7 (7/1/2024 - 12/31/2024)"
                                placeHolder="29000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod7 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod7 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 8 (1/1/2025 - 6/30/2025)"
                                placeHolder="10000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod8 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod8 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 9 (7/1/2025 - 12/31/2025)"
                                placeHolder="10000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod9 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod9 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                            <AddonInputComponent
                                label="O&amp;M Costs during Reporting Period 10 (1/1/2026 - 6/30/2026)"
                                placeHolder="30000"
                                value={props.project.bmpGeneralOAndM.omReportingPeriod10 ?? ""}
                                setValue={(value) => {
                                    const updated = props.project.bmpGeneralOAndM;
                                    updated.omReportingPeriod10 = value !== "" ? value : null;
                                    setOAndM(updated);
                                }}
                                inputType="number"
                                theme="primary"
                                addonSide="left"
                                addonContent="$"
                            />

                        </>
                }
            </Translation>
        </div>
    )
}
