
export const customFormat = (number: number) => {
    let format = { letter: '', denominator: 1 };
    if (number >= 1000000000) format = { letter: 'B', denominator: 1000000000 };
    else if (number >= 1000000) format = { letter: 'M', denominator: 1000000 };
    else if (number >= 1000) format = { letter: 'k', denominator: 1000 };
    else if (number <= -1000000000) format = { letter: 'B', denominator: 1000000000 };
    else if (number <= -1000000) format = { letter: 'M', denominator: 1000000 };
    else if (number <= -1000) format = { letter: 'k', denominator: 1000 };
    let { letter, denominator } = format
    return `${+(number / denominator).toFixed(number / denominator < 100 ? 1 : 0)}${letter}`
}

export const dateDMY = (inDate: any) => {
    let dt = new Date(inDate);
    if (dt.getFullYear() === 1) { return "-"; }
    return (dt.getMonth() + 1) + "/" + dt.getDate() + "/" + dt.getFullYear()
}

export const dateYY = (inDate: any) => {
    let dt = new Date(inDate);
    if (dt.getFullYear() === 1) { return "-"; }
    return (dt.getFullYear().toString().substring(2))
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export const toMillions = (number: number) => `${+(number / 1000000).toFixed(number / 1000000 < 1 ? 2 : 1)}M`

export const formatAcres = (number: number) => number && new Intl.NumberFormat().format(+(number).toFixed(number < 10 ? 2 : 1));

export const formatWithCommas = (number: number) => number && new Intl.NumberFormat().format(number);

export const formatPercent = (number: number) => number && +(number).toFixed(number < 1 ? 1 : 0);

//generates random id;
// used as a random 'key={guid()}' on react components to retrigger classes, therefore animations on re-render
export const getGuid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
//"c2181edf-041b-0a61-3651-79d671fa3db7"

export const truncate = (string: string, wordLimit: number) => {
    // do truthy checks and return an empty string anyway so it never breaks the app
    if (!!string) {
        let array = string.split(" ");
        let ellipsis = array.length > wordLimit ? ' ...' : ''
        return `${array.splice(0, wordLimit).join(" ")}${ellipsis}`;
    }
    else return ""
}

export const getStatusLabel = (status: number): string => {
    //enumReportDueStatus
    if (status === 1) {
        return "In Progress";
    }
    if (status === 2) {
        return "Submitted";
    }
    if (status === 3) {
        return "Complete";
    }
    if (status === 8) {
        return "Not Started";
    }
    if (status === 9) {
        return "Overdue";
    }
    if (status === 99) {
        return "N/A"
    }
    return "unknown";
}


export const getReportType = (type: number): string => {
    //enumReportType
    if (type === 1) {
        return "Quarterly";
    }
    if (type === 2) {
        return "Annual";
    }
    if (type === 3) {
        return "As Needed";
    }

    return "unknown";
}
