import { Menu, Sidebar } from "semantic-ui-react";

export interface ISidebarComponent {
  children: any;
  // dispatchSidebar: Function;
  sidebarStore: any;
  sidebarName: string;
  screenSize: number;
  screenDisplayType: string;
}

export const SidebarComponent: any = (props: ISidebarComponent) => {
 
  const getWidth = () => {
    let width: string;
    // if name is EditProjectPage
    if (props.sidebarStore.current.name === "EditProjectPage") {
      width = props.screenDisplayType === "desktop" ? "75%" : "100%";
    // if any other page on desktop
    } else if (props.screenDisplayType === "desktop") {
      width = "330px"
    // if mobile
    } else {
      width = "100%";
    }
    return width;
  };

  return (
    <div>
      <div className="generic-sidebar-wrapper">
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          vertical
          visible={
            props.sidebarStore?.current.name === props.sidebarName
              ? true
              : false
          }
          style={{ width: getWidth() }}
          className="generic-sidebar"
        >
          {props.children}
        </Sidebar>
      </div>
    </div>
  );
};
