import { Form } from "semantic-ui-react";
import { IToggleComponentProps } from "./IToggleComponentProps";
import './ToggleComponent.scss';

export function ToggleComponent(props: IToggleComponentProps) {
    return (
        <div className="toggle-component-basic">

            <Form.Field>
                <div className="ui form toggle-container">
                    <div className="top-label-div">
                        <label className="top-label">{props.topLabel ? props.topLabel : ''}</label>

                        {
                            (props.topLabel && props.toolTipInfo) &&
                            <div className="tool-tip-entry-icon pl-point5 padding-top-label-tooltip">
                            </div>
                        }

                    </div>
                    <div className="toggle-div">
                        <div className={`field ${props.requiredField ? "required field" : ""} ${props.theme}`}
                            onClick={(e) => props.setValue(!props.value)}>

                            <div className="toggle-wrapper">
                                <div className="ui checkbox toggle">
                                    <input
                                        type="checkbox"
                                        className="hidden"
                                        checked={props.value}
                                    />
                                    <label></label>
                                </div>
                                <div className="toggle-label">{props.sideLabel}</div>
                            </div>
                        </div>
                        {
                            (!props.topLabel && props.toolTipInfo) &&
                            <div className="tool-tip-entry-icon pl-point5 padding-side-label-tooltip">
                            </div>
                        }
                    </div>
                </div>
            </Form.Field>
        </div>
    )
}
